import { useEffect } from "react";
import { getAccountCreateAllowed } from "../../../store/actions/account";
import { getUser } from "../../../store/actions/auth";
import { connect } from "../../../store";
import { withRouter } from "react-router-dom";
import Page from "../../../Layout/Page";

function AccountCreate({ getAccountCreateAllowed, data, loading, match }) {
  useEffect(() => {
    getAccountCreateAllowed();
    console.log(getUser());
  }, [getAccountCreateAllowed]);

  return (
    <>
      <Page>
        {console.log(match)}
        <h1>Account Create</h1>
      </Page>
    </>
  );
}

export default withRouter(
  connect(
    (state) => ({
      data: state.account.create.data,
      loading: state.account.create.loading,
      error: state.account.create.error,
    }),
    { getAccountCreateAllowed }
  )(AccountCreate)
);
