import { useEffect } from "react";
import {
  getStudentDetailsDisponibility,
  getStudentDetailsDisponibilityUpdate,
} from "../../../store/actions/students";
import { connect } from "../../../store";
import Icon from "../../Icon";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DayLabel = styled.div`
  height: 25px;
  width: 100%;
  text-align: center;
`;

const SelectContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  border: 1px solid black;
  box-sizing: border-box;
`;

const TimeContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
`;

const CreateButton = styled.button`
  height: 45px;
  width: 45px;
  background-color: rgb(0, 82, 255);
  border-radius: 45px;
  position: fixed;
  bottom: 45px;
  right: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding: 0;
  margin: 0;
  line-height: 45px;
  font-size: 20px;
  z-index: 9999;
  overflow: hidden;
  * {
    z-index: 1;
  }
  &:after {
    z-index: 0;
    content: "";
    background-color: transparent;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:hover:after {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &:active:after {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const labelDays = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];

const labelTime = [
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
];

function CalendarAvailability({
  match,
  getStudentDetailsDisponibility,
  getStudentDetailsDisponibilityUpdate,
  table,
  loading,
}) {
  useEffect(() => {
    getStudentDetailsDisponibility(match.params.studentsid);
  }, [getStudentDetailsDisponibility, match.params.studentsid]);

  const handleClick = (e) => {
    if (e.target.style.background === "rgb(212, 212, 212)") {
      e.target.style.background = "rgb(27, 142, 204)";
      e.target.style.value = false;
      table[e.target.style.indexX][e.target.style.indexY] =
        e.target.style.value;
    } else {
      e.target.style.background = "rgb(212, 212, 212)";
      e.target.style.value = true;
      table[e.target.style.indexX][e.target.style.indexY] =
        e.target.style.value;
    }
  };

  const switchBackground = (val) => {
    if (val === true) {
      return "rgb(212, 212, 212)";
    } else {
      return "rgb(27, 142, 204)";
    }
  };

  return (
    <>
      <Container>
        <ColumnContainer
          style={{
            flex: 1,
            height: "91.5%",
            marginTop: "40px",
          }}
        >
          {labelTime.map((time, index) => (
            <TimeContainer key={index * 1000 - index}>{time}</TimeContainer>
          ))}
        </ColumnContainer>
        {!loading &&
          table?.map((day, index) => (
            <ColumnContainer key={index} style={{ flex: 3, height: "100%" }}>
              <TopContainer>
                <DayLabel>{labelDays[index]}</DayLabel>
              </TopContainer>
              {!loading &&
                day?.map((item, ind) => (
                  <SelectContainer
                    key={index * 100 + ind * 5}
                    style={{
                      background: switchBackground(item),
                      indexX: `${index}`,
                      indexY: `${ind}`,
                      value: item,
                    }}
                    onClick={(e) => {
                      handleClick(e);
                    }}
                  ></SelectContainer>
                ))}
            </ColumnContainer>
          ))}
      </Container>
      <CreateButton
        onClick={() =>
          getStudentDetailsDisponibilityUpdate(match.params.studentsid, table)
        }
      >
        <Icon name="done" />
      </CreateButton>
    </>
  );
}

export default withRouter(
  connect(
    (state) => ({
      table: state.students.details.disponibility.data.disponibility,
      loading: state.students.details.disponibility.loading,
      error: state.students.details.disponibility.error,
      actionWithState: getStudentDetailsDisponibility.bind(state),
    }),
    { getStudentDetailsDisponibility, getStudentDetailsDisponibilityUpdate }
  )(CalendarAvailability)
);
