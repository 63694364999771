export default function Image({ background }) {
  return (
    <div
      style={{
        background: `url("${process.env.PUBLIC_URL}/assets/googlecalendar.png") no-repeat  `,
      }}
    >
      {console.log(process.env.PUBLIC_URL)}
    </div>
  );
}
