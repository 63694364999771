import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const TextContainer = styled.text`
  flex: 1;
`;

export const RowContainer = styled.div`
  display: flex;
  width: 90%;
  height: 30px;
  padding-top: 10px;
  flex-direction: row;
  justify-content: center;
`;

export const PopUpContainer = styled.div`
  display: flex;
  width: 500px;
  /* height: 250px; */
  height: 200px;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-color: white;
  z-index: 100000;
  box-sizing: border-box;
  position: absolute;
  border: solid 1px;
  border-radius: 3px;
  /* left: calc(50% - 250px); */
  /* align-items: center; */
  top: 50px;
`;

export const Input = styled.input`
  width: 90%;
  height: 40px;
  margin: 5px 0px 0px 0px;
  flex-direction: column;
  box-sizing: border-box;
`;

export const Button = styled.button`
  width: 150px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background: #1a73e8;
  margin: 5px 0px;
  border-radius: 3px;
  color: white;
`;

export const TopContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentContainer = styled.div``;

export const ContentContainer1 = styled.div`
  position: absolute;
  bottom: 1px;
  right: 3px;
`;

export const EventContainer = styled.div`
  display: flex;
  flex: 1;
  background: #3a7aff;
  color: white;
  position: absolute;
  border: 1px solid grey;
  font-size: 10px;
  font-weight: bold;
  box-sizing: border-box;
  border-radius: 3px;
  padding-left: 3px;
  padding-top: 2px;
  padding-bottom: 15px;
  right: 5px;
  left: 5px;
  /* width: ${({ full }) => {
    if (full) return "calc(100% - 20px)";
    return "calc(100% - 58px)";
  }}; */
  width: calc(100% - 10px);
  height: 8.33333%;
  overflow: hidden;
  flex-direction: column;
`;

export const NonDispoContainer = styled.div`
  display: flex;
  flex: 1;
  background: #ff6666;
  opacity: 1;
  color: white;
  position: absolute;
  border: 1px solid grey;
  font-size: 10px;
  font-weight: bold;
  box-sizing: border-box;
  border-radius: 3px;
  padding-left: 3px;
  padding-top: 2px;
  padding-bottom: 15px;
  right: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: 8.33333%;
  overflow: hidden;
  flex-direction: column;
`;

export const DispoContainer = styled.div`
  display: flex;
  flex: 1;
  background: #98e698;
  opacity: 0.75;
  color: white;
  position: absolute;
  border: 1px solid grey;
  font-size: 10px;
  font-weight: bold;
  box-sizing: border-box;
  border-radius: 3px;
  padding-left: 3px;
  padding-top: 2px;
  padding-bottom: 15px;
  right: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: 8.33333%;
  overflow: hidden;
  flex-direction: column;
`;

export const CurrentTimeContainer = styled.div`
  box-sizing: border-box;
  width: calc(100% - 78px);
  height: 1px;
  right: 20px;
  /* margin-top: 65px; */
  background: red;
  position: absolute;
  /* z-index: 10; */
  &:before {
    content: attr(data-time);
    position: absolute;
    left: -42px;
    font-size: 13px;
    top: -10px;
    background: red;
    color: white;
    box-sizing: border-box;
    padding: 2px 3px;
    border-radius: 3px;
  }
`;

export const EventLabel = styled.div`
  display: flex;
  background: lightgrey;
  position: absolute;
  right: 0px;
  width: 90%;
  height: 8.33333%;
  margin: 0 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const DayNumber = styled.div`
  height: 25px;
  width: 50px;
  border-radius: 25px;
  text-align: center;
  &.active {
    color: white;
    background: red;
  }
`;

export const DayLabel = styled.div`
  height: 25px;
  width: 100%;
  text-align: center;
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
`;

export const TimeGrid = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const TimeGridLine = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  height: 21px;
  width: 100%;
  top: 0;
  span {
    position: absolute;
    left: -38px;
  }
`;

export const TimeGridLineEmpty = styled.div`
  margin: 0 auto;
  box-sizing: border-box;
  height: 1px;
  background: lightgrey;
  width: calc(100% - 10px);
`;
