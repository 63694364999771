import { useEffect, useState } from "react";
import Page from "../../../../Layout/Page";
import Selector from "../../../../Common/Form/Selector";
import SubTitleBar from "../../../../Common/Form/SubTitleBar";
import FieldText from "../../../../Common/Form/FieldText";
import Button from "../../../../Common/Form/Button";
import {
  LeftContainer,
  Container,
  RightContainer,
  VariableContainer,
} from "./Styled";
import { FormContainer } from "../../../../Common/Form/FormContainer";
import Icon from "../../../../Common/Icon";
import { connect } from "../../../../store";
import {
  getFormerDetails,
  getFormerDelete,
  getFormerDetailsCalendarId,
} from "../../../../store/actions/formers";

function FormersDetail({
  data,
  calendarId,
  match,
  getFormerDetails,
  getFormerDelete,
  getFormerDetailsCalendarId,
  history,
}) {
  const [former, setFormer] = useState(data);
  const [disabled, setDisabled] = useState(true);
  const [newCalendarId, setNewCalendarId] = useState(calendarId);

  useEffect(() => {
    getFormerDetails(match.params.formersid, {});
    getFormerDetailsCalendarId(match.params.formersid, {});
  }, [getFormerDetails, getFormerDetailsCalendarId, match.params.formersid]);

  useEffect(() => {
    if (data._id) {
      setFormer(data);
      setNewCalendarId(calendarId);
    }
  }, [data._id, data, calendarId]);

  useEffect(() => {
    if (calendarId) {
      setNewCalendarId(calendarId);
    }
  }, [calendarId]);

  const handleChange = (fieldName) => (e) => {
    const value = e.target.value;
    setFormer({ ...former, [fieldName]: value });
  };

  const handleChangeCalendar = (e) => {
    const value = e.target.value;
    setNewCalendarId(value);
  };

  const handleClickDelete = (e) => {
    e.preventDefault();
    console.log("delete");
    getFormerDelete(match.params.formersid, {});
    history.push(`/formers`);
  };

  const handleClickSubmit = () => {
    setDisabled(true);
  };

  const statut = { internal: "Interne", external: "Externe" };
  const frequency = {
    daily: "par jour",
    monthly: "par mois",
    fixed: "forfait",
  };
  const frequencyLabel = ["par jour", "par mois", "forfait"];
  const civility = ["-", "M", "Mme", "Mlle"];

  return (
    <>
      {console.log(statut.internal)}
      <Page>
        <FormContainer>
          {/* <Form> */}
          <div className="form-row space-between">
            <SubTitleBar>Informations personnels</SubTitleBar>
            <Icon
              name={disabled ? "edit" : "cancel"}
              className="button-edit"
              onClick={() => setDisabled(!disabled)}
            />
          </div>
          <Container>
            <LeftContainer>
              <div className="form-row">
                <VariableContainer style={{ flex: 0.3 }}>
                  <Selector
                    select={former.civility}
                    label="Civilité"
                    data={civility}
                    disabled={disabled}
                  />
                </VariableContainer>
                <VariableContainer style={{ flex: 1 }}>
                  <FieldText
                    value={former.lastname}
                    onChange={handleChange("lastname")}
                    label="Nom :"
                    fieldLabel="Nom"
                    disabled={disabled}
                  ></FieldText>
                </VariableContainer>
                <VariableContainer style={{ flex: 1 }}>
                  <FieldText
                    value={former.firstname}
                    onChange={handleChange("firstname")}
                    label="Prénom :"
                    fieldLabel="Prénom"
                    disabled={disabled}
                  ></FieldText>
                </VariableContainer>
              </div>
              <div className="form-row">
                <VariableContainer style={{ flex: 3 }}>
                  <FieldText
                    value={former.email}
                    onChange={handleChange("email")}
                    label="Email :"
                    fieldLabel="Email"
                    disabled={disabled}
                  ></FieldText>
                </VariableContainer>
                <VariableContainer style={{ flex: 1 }}>
                  <FieldText
                    value={former.code}
                    onChange={handleChange("code")}
                    label="Code interne :"
                    fieldLabel="Code interne"
                    disabled={disabled}
                  ></FieldText>
                </VariableContainer>
              </div>
              <div className="form-row">
                <VariableContainer style={{ flex: 0.5 }}>
                  <FieldText
                    label="Ville de naissance :"
                    fieldLabel="Ville de naissance"
                    value={former.birthCity}
                    onChange={handleChange("birthCity")}
                    disabled={disabled}
                  ></FieldText>
                </VariableContainer>
                <VariableContainer style={{ flex: 0.5 }}>
                  <FieldText
                    value={former.birthRegion}
                    onChange={handleChange("birthRegion")}
                    label="Région de naissance :"
                    fieldLabel="Région de naissance"
                    disabled={disabled}
                  ></FieldText>
                </VariableContainer>
              </div>
              <div className="form-row">
                <VariableContainer style={{ flex: 0.5 }}>
                  <FieldText
                    label="Date de naissance :"
                    fieldLabel="Date de naissance"
                    value={former.birthdate}
                    onChange={handleChange("birthdate")}
                    disabled={disabled}
                  ></FieldText>
                </VariableContainer>
              </div>
              <div className="form-row">
                <VariableContainer style={{ flex: 1 }}>
                  <FieldText
                    label="Id de l'agenda Google :"
                    fieldLabel="Id de l'agenda Google"
                    value={newCalendarId}
                    disabled={disabled}
                    onChange={handleChangeCalendar}
                  ></FieldText>
                </VariableContainer>
              </div>
            </LeftContainer>
            <RightContainer>
              <div className="form-row">
                <FieldText
                  value={former.roadAddress}
                  onChange={handleChange("roadAddress")}
                  label="Adresse :"
                  fieldLabel="Adresse"
                  disabled={disabled}
                ></FieldText>
              </div>
              <div className="form-row">
                <FieldText
                  label="Code postal :"
                  fieldLabel="Code postal"
                  value={former.cityCode}
                  onChange={handleChange("cityCode")}
                  disabled={disabled}
                ></FieldText>
                <FieldText
                  value={former.city}
                  onChange={handleChange("city")}
                  label="Ville :"
                  fieldLabel="Ville"
                  disabled={disabled}
                ></FieldText>
              </div>
              <div className="form-row">
                <FieldText
                  label="Pays :"
                  fieldLabel="Pays"
                  value={former.country}
                  onChange={handleChange("country")}
                  disabled={disabled}
                ></FieldText>
                <FieldText
                  value={former.phone}
                  onChange={handleChange("phone")}
                  label="Téléphone :"
                  fieldLabel="Téléphone"
                  disabled={disabled}
                ></FieldText>
              </div>
            </RightContainer>
          </Container>
          <div className="form-row">
            <VariableContainer style={{ flex: 1 }}>
              <FieldText
                label="Statut par défaut :"
                fieldLabel="Statut par défaut"
                value={statut[former.status]}
                onChange={handleChange("status")}
                disabled={disabled}
              ></FieldText>
            </VariableContainer>
            <VariableContainer style={{ flex: 1 }}>
              <FieldText
                label="Tarif par défaut :"
                fieldLabel="Tarif par défaut"
                value={former.cost}
                onChange={handleChange("cost")}
                disabled={disabled}
              ></FieldText>
            </VariableContainer>
            <VariableContainer style={{ flex: 1 }}>
              <Selector
                select={frequency[former.costMode]}
                label=""
                data={frequencyLabel}
                disabled={disabled}
              />
            </VariableContainer>
          </div>
          <div className="form-row">
            {/* <Button disabled={disabled} onClick={() => setDisabled(disabled)}> */}
            <Button disabled={disabled} onClick={handleClickSubmit}>
              Valider
            </Button>
            <Button onClick={handleClickDelete} disabled={disabled}>
              Supprimer
            </Button>
          </div>
          {/* </Form> */}
        </FormContainer>
      </Page>
    </>
  );
}

export default connect(
  (state) => ({
    data: state.formers.details.information.data,
    loading: state.formers.details.information.loading,
    error: state.formers.details.information.error,
    calendarId: state.formers.details.calendarid.data.calendarId,
  }),
  {
    getFormerDetails,
    getFormerDelete,
    getFormerDetailsCalendarId,
  }
)(FormersDetail);
