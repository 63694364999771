import { useEffect } from "react";
import Page from "../../../../../Layout/Page";
import { Table, Td, Th, Tr, Tj } from "../../../../../Common/Table";
import Formation from "../../../../../Common/Formation";
import { connect } from "../../../../../store";
import { getFormerDetailsStudents } from "../../../../../store/actions/formers";

function FormersStudentsListing({
  match,
  getFormerDetailsStudents,
  loading,
  data,
}) {
  useEffect(() => {
    getFormerDetailsStudents(match.params.formersid);
  }, [getFormerDetailsStudents, match.params.formersid]);

  return (
    <>
      <Page>
        <Table>
          <thead>
            <tr>
              <Th>Name</Th>
              <Th style={{ textAlign: "right" }}></Th>
              <Th style={{ textAlign: "right" }}></Th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <Tr>
                <Tj colspan={3}>Loading</Tj>
                <Tj></Tj>
                <Tj></Tj>
              </Tr>
            )}
            {!loading &&
              data.map((item) => (
                <Tr
                  key={`${item._id}`}
                  // onClick={() =>
                  //   history.push(`/formers/${item._id}/students/1`)
                  // }
                >
                  <Td>
                    <Formation name={item.name} />
                  </Td>
                  <Td style={{ textAlign: "right" }}></Td>
                  <Td style={{ textAlign: "right" }}></Td>
                </Tr>
              ))}
          </tbody>
        </Table>
      </Page>
    </>
  );
}

export default connect(
  (state) => ({
    data: state.formers.details.students.data,
    loading: state.formers.details.students.loading,
    error: state.formers.details.students.error,
  }),
  { getFormerDetailsStudents }
)(FormersStudentsListing);
