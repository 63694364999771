import { useEffect } from "react";
import { connect } from "../../../../../store";
import Page from "../../../../../Layout/Page";
import {getFormations} from "../../../../../store/actions/formations"
import { getFormerDetailsSearchCreate } from "../../../../../store/actions/formers";
// import { getFormerDetailsSearch } from "../../../../../store/actions/formers";
import { Table, Td, Th, Tr } from "../../../../../Common/Table";
import Item from "../../../../../Common/Item";
import { withRouter } from "react-router-dom";

function FormersDetailsSearchCreate({
  history,
  loading,
  data,
  match,
  getFormations,
  getFormerDetailsSearchCreate
}) {
  // let table = [];
  // let result = [];

  // useEffect(() => {
  //   getFormerDetailsSearchCreate(match.params.studentsid);
  //   getFormerDetailsSearch(match.params.studentsid);
  // }, [
  //   getFormerDetailsSearch,
  //   getFormerDetailsSearchCreate,
  //   match.params.studentsid,
  // ]);

  // data?.programs?.map((item) => {
  //   result.push([item.name, item.id]);
  //   // console.log(result);
  //   return result;
  // });

  // oldData.instructor?.trainingSessions.map((item, index) =>
  //   table.push(item.program.parentId)
  // );

  // data?.programs?.map((item, index) => {
  //   return table.map((litem) => {
  //     if (item.id === litem) {
  //       result.splice(index, 1);
  //     }
  //     return result;
  //   });
  // });

  useEffect(() => {
    getFormations();
  }, [getFormations]);

  const handleClick = (item) => {
    history.push(
      `/formers/${match.params.formersid}/search`
    );
    getFormerDetailsSearchCreate(match.params.formersid, {item});
    // console.log("e : ", e.target);
  }

  return (
    <>
      {console.log(data)}
      <Page>
        <Table>
          <thead>
            <tr>
              <Th>Formations</Th>
              <Th style={{ textAlign: "right" }}></Th>
              <Th style={{ textAlign: "right" }}></Th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <Tr>
                <Td>Loading</Td>
                <Td style={{ textAlign: "right" }}></Td>
                <Td style={{ textAlign: "right" }}></Td>
              </Tr>
            )}
            {!loading &&
              data.programs?.map((item, index) => (
                <Tr
                  key={index}
                  onClick={() => {
                    handleClick(item);
                  }}
                >
                  <Td>
                    <Item label={item.name}></Item>
                  </Td>
                  <Td style={{ textAlign: "right" }}></Td>
                  <Td style={{ textAlign: "right" }}></Td>
                </Tr>
              ))}
          </tbody>
        </Table>
      </Page>
    </>
  );
}

export default withRouter(
  connect(
    (state) => ({
      data: state.formations.listing.data,
      loading: state.formations.listing.loading,
      error: state.formations.listing.error,
      actionWithState: getFormations.bind(state),
    }),
    { getFormations, getFormerDetailsSearchCreate }
    // (state) => ({
    //   data: state.formers.details.search.create.data,
    //   oldData: state.formers.details.search.listing.data,
    //   loading: state.formers.details.search.create.loading,
    //   error: state.formers.details.search.create.error,
    //   actionWithState: getFormerDetailsSearchCreate.bind(state),
    // }),
    // { getFormerDetailsSearchCreate, getFormerDetailsSearch }
  )(FormersDetailsSearchCreate)
);
