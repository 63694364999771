import { Route, Switch } from "react-router";
import SelectFormer from "./Former";
import SelectCalendar from "./Calendar";

function Select() {
  return (
    <Switch>
      <Route
        exact
        path="/students/:studentsid/search/create/:formationsid"
        component={SelectFormer}
      />
      <Route
        strict
        path="/students/:studentsid/search/create/:formationsid/:formersid"
        component={SelectCalendar}
      />
    </Switch>
  );
}

export default Select;
