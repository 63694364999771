const defaultState = {
  listing: {
    error: false,
    loading: false,
    query: "",
    pageInfo: {
      skip: 0,
      limit: 10,
      total: 0,
    },
    data: [],
  },
  calendarOn: {
    loading: false,
    pending: false,
    data: {},
  },
  create: {
    error: false,
    loading: false,
    data: {},
  },
  formationslisting: {
    error: false,
    loading: false,
    data: [],
  },
  details: {
    information: {
      loading: false,
      pending: false,
      data: {},
    },
    calendarid: {
      loading: false,
      pending: false,
      data: {},
    },
    calendaridUpdate: {
      loading: false,
      pending: false,
      data: {},
    },
    calendar: {
      loading: false,
      pending: false,
      data: [],
    },
    students: {
      loading: false,
      pending: false,
      data: [],
    },
    delete: {
      error: false,
      loading: false,
      data: {},
    },
    search: {
      listing: {
        error: false,
        loading: false,
        data: [],
      },
      create: {
        loading: false,
        error: false,
        data: [],
      },
      details: {
        loading: false,
        pending: false,
        data: {},
      },
    },
  },
};

export default function formers(state = defaultState, action) {
  switch (action.type) {
    case "GET_FORMERS_LISTING_LOADING":
      return {
        ...state,
        listing: { ...state.listing, loading: true, error: false },
      };
    case "GET_FORMERS_LISTING_SUCCESS":
      return {
        ...state,
        listing: { ...state.listing, loading: false, data: action.payload },
      };
    case "GET_FORMERS_LISTING_ERROR":
      return {
        ...state,
        listing: { ...state.listing, loading: false, error: true },
      };
    case "UPDATE_FORMERS_LISTING_QUERY":
      return {
        ...state,
        listing: { ...state.listing, query: action.payload },
      };
    case "GET_FORMERS_CALENDAR_ON_LOADING":
      return {
        ...state,
        calendarOn: { ...state.calendarOn, loading: true, error: false },
      };
    case "GET_FORMERS_CALENDAR_ON_SUCCESS":
      return {
        ...state,
        calendarOn: {
          ...state.calendarOn,
          loading: false,
          data: action.payload,
        },
      };
    case "GET_FORMERS_CALENDAR_ON_ERROR":
      return {
        ...state,
        calendarOn: { ...state.calendarOn, loading: false, error: true },
      };
    case "GET_FORMERS_CREATE_LOADING":
      return {
        ...state,
        create: { ...state.create, loading: true, error: false },
      };
    case "GET_FORMERS_CREATE_SUCCESS":
      return {
        ...state,
        create: { ...state.create, loading: false, data: action.payload },
      };
    case "GET_FORMERS_CREATE_ERROR":
      return {
        ...state,
        create: { ...state.create, loading: false, error: true },
      };
    case "GET_FORMERS_FORMATIONS_LISTING_LOADING":
      return {
        ...state,
        formationslisting: {
          ...state.formationslisting,
          loading: true,
          error: false,
        },
      };
    case "GET_FORMERS_FORMATIONS_LISTING_SUCCESS":
      return {
        ...state,
        formationslisting: {
          ...state.formationslisting,
          loading: false,
          data: action.payload,
        },
      };
    case "GET_FORMERS_FORMATIONS_LISTING_ERROR":
      return {
        ...state,
        formationslisting: {
          ...state.formationslisting,
          loading: false,
          error: true,
        },
      };
    case "GET_FORMERS_DETAILS_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          information: {
            ...state.details.information,
            loading: true,
            error: false,
            data: {},
          },
        },
      };
    case "GET_FORMERS_DETAILS_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          information: {
            ...state.details.information,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_FORMERS_DETAILS_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          information: {
            ...state.details.information,
            loading: false,
            error: true,
            data: {},
          },
        },
      };
    case "GET_FORMERS_DETAILS_CALENDAR_ID_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          calendarid: {
            ...state.details.calendarid,
            loading: true,
            error: false,
            data: {},
          },
        },
      };
    case "GET_FORMERS_DETAILS_CALENDAR_ID_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          calendarid: {
            ...state.details.calendarid,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_FORMERS_DETAILS_CALENDAR_ID_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          calendarid: {
            ...state.details.calendarid,
            loading: false,
            error: true,
            data: {},
          },
        },
      };
    case "GET_FORMERS_DETAILS_CALENDAR_ID_UPDATE_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          calendaridUpdate: {
            ...state.details.calendaridUpdate,
            loading: true,
            error: false,
            data: {},
          },
        },
      };
    case "GET_FORMERS_DETAILS_CALENDAR_ID_UPDATE_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          calendaridUpdate: {
            ...state.details.calendaridUpdate,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_FORMERS_DETAILS_CALENDAR_ID_UPDATE_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          calendaridUpdate: {
            ...state.details.calendaridUpdate,
            loading: false,
            error: true,
            data: {},
          },
        },
      };
    case "GET_FORMERS_DETAILS_CALENDAR_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          calendar: {
            ...state.details.calendar,
            loading: true,
            error: false,
          },
        },
      };
    case "GET_FORMERS_DETAILS_CALENDAR_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          calendar: {
            ...state.details.calendar,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_FORMERS_DETAILS_CALENDAR_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          calendar: {
            ...state.details.calendar,
            loading: false,
            error: true,
          },
        },
      };
    case "GET_FORMERS_DETAILS_CALENDAR_ADD_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          calendar: {
            ...state.details.calendar,
            loading: true,
            error: false,
          },
        },
      };
    case "GET_FORMERS_DETAILS_CALENDAR_ADD_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          calendar: {
            ...state.details.calendar,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_FORMERS_DETAILS_CALENDAR_ADD_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          calendar: {
            ...state.details.calendar,
            loading: false,
            error: true,
          },
        },
      };
    case "GET_FORMERS_DETAILS_STUDENTS_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          students: {
            ...state.details.students,
            loading: true,
            error: false,
          },
        },
      };
    case "GET_FORMERS_DETAILS_STUDENTS_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          students: {
            ...state.details.students,
            loading: false,
            data: action.payload,
          },
        },
      };
    case "GET_FORMERS_DETAILS_STUDENTS_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          students: {
            ...state.details.students,
            loading: false,
            error: true,
          },
        },
      };
    case "GET_FORMERS_DELETE_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          delete: {
            ...state.details.delete,
            loading: true,
            error: false,
            data: {},
          },
        },
      };
    case "GET_FORMERS_DELETE_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          delete: {
            ...state.details.delete,
            loading: false,
            error: false,
            data: action.payload,
          },
        },
      };
    case "GET_FORMERS_DELETE_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          delete: {
            ...state.details.delete,
            loading: false,
            error: true,
            data: {},
          },
        },
      };
    case "GET_FORMERS_DETAILS_SEARCH_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            listing: {
              ...state.listing,
              loading: true,
              error: false,
            },
          },
        },
      };
    case "GET_FORMERS_DETAILS_SEARCH_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            listing: { ...state.listing, loading: false, data: action.payload },
          },
        },
      };
    case "GET_FORMERS_DETAILS_SEARCH_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            listing: { ...state.listing, loading: false, error: true },
          },
        },
      };
    case "GET_FORMERS_DETAILS_SEARCH_CREATE_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            create: {
              ...state.create,
              loading: true,
              error: false,
            },
          },
        },
      };
    case "GET_FORMERS_DETAILS_SEARCH_CREATE_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            create: { ...state.create, loading: false, data: action.payload },
          },
        },
      };
    case "GET_FORMERS_DETAILS_SEARCH_CREATE_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            create: { ...state.create, loading: false, error: true },
          },
        },
      };
    case "GET_FORMERS_DETAILS_SEARCH_DETAILS_LOADING":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            details: {
              ...state.details,
              loading: true,
              error: false,
            },
          },
        },
      };
    case "GET_FORMERS_DETAILS_SEARCH_DETAILS_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            details: {
              ...state.details,
              loading: false,
              data: action.payload,
            },
          },
        },
      };
    case "GET_FORMERS_DETAILS_SEARCH_DETAILS_ERROR":
      return {
        ...state,
        details: {
          ...state.details,
          search: {
            ...state.details.search,
            details: {
              ...state.details,
              loading: false,
              error: true,
            },
          },
        },
      };
    default:
      return state;
  }
}
