import { withRouter } from "react-router";
import { connect } from "../../../store";
import { useEffect } from "react";
import {
  getFormers,
  getFormersCalendarOn,
} from "../../../store/actions/formers";
import CreateButton from "../../../Common/CreateButton";
import Icon from "../../../Common/Icon";
import { Table, Td, Th, Tr, Tj } from "../../../Common/Table";
import User from "../../../Common/User";
import Page from "../../../Layout/Page";
import SearchBar from "../../../Layout/SearchBar";

function FormersListing({
  history,
  loading,
  data,
  calendarOn,
  getFormers,
  getFormersCalendarOn,
  query,
}) {
  useEffect(() => {
    getFormers({ query });
    getFormersCalendarOn();
  }, [getFormers, getFormersCalendarOn, query]);

  return (
    <>
      <Page>
        <SearchBar query={query} search={getFormers}></SearchBar>
        <Table>
          <thead>
            <tr>
              <Th>Name</Th>
              <Th style={{ textAlign: "right" }}></Th>
              <Th style={{ textAlign: "right" }}>Google Agenda</Th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <Tr>
                <Tj colspan={3}>Loading</Tj>
                <Tj></Tj>
                <Tj></Tj>
              </Tr>
            )}
            {!loading &&
              data.map((item) => (
                <Tr
                  key={`${item._id}`}
                  onClick={() => history.push(`/formers/${item._id}`)}
                >
                  <Td>
                    <User name={item.lastname} lastname={item.firstname} />
                  </Td>
                  <Td style={{ textAlign: "right" }}></Td>
                  {calendarOn[`${item._id}`] ? (
                    <Td style={{ textAlign: "right" }}>
                      <Icon name="calendar_month"></Icon>
                    </Td>
                  ) : (
                    <Td style={{ textAlign: "right" }}></Td>
                  )}
                </Tr>
              ))}
          </tbody>
        </Table>
        <CreateButton to="/formers/create">
          <Icon name="add" />
        </CreateButton>
      </Page>
    </>
  );
}

export default withRouter(
  connect(
    (state) => ({
      query: state.formers.listing.query,
      data: state.formers.listing.data,
      loading: state.formers.listing.loading,
      error: state.formers.listing.error,
      calendarOn: state.formers.calendarOn.data,
      actionWithState: getFormers.bind(state),
    }),
    { getFormers, getFormersCalendarOn }
  )(FormersListing)
);
