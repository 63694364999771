
import { useState } from "react";
import Year from "./Year";
import Month from "./Month";
import WeekInstructor from "./WeekInstructor";
import DayInstructor from "./DayInstructor";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  border-radius: 3px;
`;

const Content = styled.div`
  flex: 1;
  background: white;
  margin-top: 10px;
  padding: 15px;
  padding-left: calc(38px + 15px);
  box-sizing: border-box;
  position: relative;
`;

const Button = styled.button`
  flex: 1;
  height: 30px;
  margin-right: 2px;
  width: 150px;
`;

const Label = styled.span`
  font-weight: bold;
  right: 2%;
  top: 0%;
  position: absolute;
`;

const labelMonths = {
  0: "Janvier",
  1: "Février",
  2: "Mars",
  3: "Avril",
  4: "Mai",
  5: "Juin",
  6: "Juillet",
  7: "Aout",
  8: "Septembre",
  9: "Octobre",
  10: "Novembre",
  11: "Décembre",
};

const nextType = {
  week: "Semaine prochaine",
  day: "Jour suivant",
};

const actualType = {
  week: "Semaine actuelle",
  day: "Jour actuel",
};

const previousType = {
  week: "Semaine précédente",
  day: " Jour précédent",
};

function SwitchCalendarView({
  displayType,
  selectedDate,
  setSelectedDate,
  setDisplayType,
  events,
  program,
  selectId,
}) {
  switch (displayType) {
    case "day":
      return (
        <DayInstructor
          currentDate={selectedDate}
          type="day"
          displayCurrentTime
          events={events}
          program={program}
          selectId={selectId.params}
          popUpPosition="calc(50% - 250px)"
        />
      );
    case "week":
      return (
        <WeekInstructor
          date={selectedDate}
          type="week"
          setCurrentDate={setSelectedDate}
          setCurrentView={setDisplayType}
          events={events}
          program={program}
          selectId={selectId.params}
          popUpPosition="400px"
        />
      );
    case "year":
      return (
        <Year
          currentdate={selectedDate}
          setCurrentDate={setSelectedDate}
          setCurrentView={setDisplayType}
          selectId={selectId.params}
        />
      );
    case "month":
    default:
      return (
        <Month
          currentDate={selectedDate}
          setCurrentDate={setSelectedDate}
          setCurrentView={setDisplayType}
        />
      );
  }
}

function getActionsByDisplayType(displayType, selectedDate, setSelectedDate) {
  switch (displayType) {
    case "day":
      return {
        prev: () => {
          const date = new Date(selectedDate);
          date.setDate(date.getDate() - 1);
          setSelectedDate(date);
        },
        next: () => {
          const date = new Date(selectedDate);
          date.setDate(date.getDate() + 1);
          setSelectedDate(date);
        },
      };
    case "week":
      return {
        prev: () => {
          const date = new Date(selectedDate);
          date.setDate(date.getDate() - 7);
          setSelectedDate(date);
        },
        next: () => {
          const date = new Date(selectedDate);
          date.setDate(date.getDate() + 7);
          setSelectedDate(date);
        },
      };
    case "year":
      return {
        prev: () => {
          const date = new Date(selectedDate);
          date.setUTCFullYear(date.getFullYear() - 1);
          setSelectedDate(date);
        },
        next: () => {
          const date = new Date(selectedDate);
          date.setUTCFullYear(date.getFullYear() + 1);
          setSelectedDate(date);
        },
      };
    case "month":
    default:
      return {
        prev: () => {
          const date = new Date(selectedDate);
          date.setMonth(date.getMonth() - 1);
          setSelectedDate(date);
        },
        next: () => {
          const date = new Date(selectedDate);
          date.setMonth(date.getMonth() + 1);
          setSelectedDate(date);
        },
      };
  }
}

function switchLabel(displayType, selectedDate) {
  switch (displayType) {
    case "day":
      return `
      ${labelMonths[selectedDate.getMonth()]} 
      ${selectedDate.getFullYear()}`;
    case "week":
      return `${
        labelMonths[selectedDate.getMonth()]
      } ${selectedDate.getFullYear()}`;
    case "year":
      return selectedDate.getFullYear();
    case "month":
    default:
      return `${
        labelMonths[selectedDate.getMonth()]
      } ${selectedDate.getFullYear()}`;
  }
}

export default function CalendarFormer({
  date = new Date(),
  events,
  program,
  selectId,
  type,
}) {
  const [displayType, setDisplayType] = useState(type);
  const [selectedDate, setSelectedDate] = useState(date);
  const actions = getActionsByDisplayType(
    displayType,
    selectedDate,
    setSelectedDate
  );
  return (
    <Container>
      <div>
        <Button onClick={() => setDisplayType("day")}>Jour</Button>
        <Button onClick={() => setDisplayType("week")}>Semaine</Button>
        <Button onClick={actions.prev}>{previousType[displayType]}</Button>
        <Button onClick={() => setSelectedDate(new Date())}>
          {actualType[displayType]}
        </Button>
        <Button onClick={actions.next}>{nextType[displayType]}</Button>
        <input
          type="date"
          style={{ width: "150px", height: "25px" }}
          onChange={(e) => setSelectedDate(new Date(e.target.value))}
          value={`${selectedDate.getFullYear()}-${String(
            selectedDate.getMonth() + 1
          ).padStart(2, "0")}-${String(selectedDate.getDate()).padStart(
            2,
            "0"
          )}`}
        />
        <Label>{switchLabel(displayType, selectedDate)}</Label>
      </div>
      <Content>
        <SwitchCalendarView
          events={events}
          program={program}
          displayType={displayType}
          selectedDate={selectedDate}
          setDisplayType={setDisplayType}
          setSelectedDate={setSelectedDate}
          selectId={selectId}
        />
      </Content>
    </Container>
  );
}
