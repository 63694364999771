import axios from "../../../api";

export function getStudents(options) {
  return (dispatch) => {
    dispatch({ type: "GET_STUDENTS_LISTING_LOADING" });
    axios
      .get("/api/students?filter=" + (options.query || ""))
      .then((response) => {
        dispatch({
          type: "GET_STUDENTS_LISTING_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENTS_LISTING_ERROR",
        });
      });
    dispatch({
      type: "UPDATE_STUDENTS_LISTING_QUERY",
      payload: options.query || "",
    });
  };
}

export function getStudentCreate(options) {
  return (dispatch) => {
    console.log("api/students/create");
    dispatch({ type: "GET_STUDENTS_CREATE_LOADING" });
    axios
      .post(`/api/students/create`, { options })
      .then((response) => {
        dispatch({ type: "GET_STUDENTS_CREATE_SUCCESS" });
      })
      .catch(() => {
        dispatch({ type: "GET_STUDENTS_CREATE_ERROR" });
      });
  };
}

export function getStudentDelete(id, options) {
  return (dispatch, ...args) => {
    dispatch({ type: "GET_STUDENTS_DELETE_LOADING" });
    axios
      .get(`/api/students/${id}/delete`)
      .then((response) => {
        dispatch({
          type: "GET_STUDENTS_DELETE_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({ type: "GET_STUDENTS_DELETE_ERROR" });
      });
  };
}

export function getStudentDetails(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_STUDENTS_DETAILS_LOADING" });
    axios
      .get(`/api/students/${id}`)
      .then((response) => {
        console.log(response.data);
        dispatch({
          type: "GET_STUDENTS_DETAILS_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_ERROR",
        });
      });
  };
}

export function getStudentDetailsCalendar(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_STUDENTS_DETAILS_CALENDAR_LOADING" });
    axios
      .get(`/api/students/${id}/calendar`)
      .then((response) => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_CALENDAR_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_CALENDAR_ERROR",
        });
      });
  };
}

export function getStudentDetailsCalendarCreate(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_STUDENTS_DETAILS_CALENDAR_CREATE_LOADING" });
    axios
      .post(`/api/students/${id}/calendar/create`, { options })
      .then((response) => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_CALENDAR_CREATE_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_CALENDAR_CREATE_ERROR",
        });
      });
  };
}

export function getStudentDetailsFormers(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_STUDENTS_DETAILS_FORMERS_LOADING" });
    axios
      .get(`/api/students/${id}/formers`)
      .then((response) => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_FORMERS_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_FORMERS_ERROR",
        });
      });
  };
}

export function getStudentDetailsSearch(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_STUDENTS_DETAILS_SEARCH_LOADING" });
    axios
      .get(`/api/students/${id}/search`)
      .then((response) => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_SEARCH_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_SEARCH_ERROR",
        });
      });
  };
}

export function getStudentDetailsCalendarFormerId(id) {
  return (dispatch) => {
    dispatch({ type: "GET_STUDENTS_DETAILS_CALENDAR_FORMERID_LOADING" });
    axios
      .get(`/api/students/${id}/calendar/formerId`)
      .then((response) => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_CALENDAR_FORMERID_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_CALENDAR_FORMERID_ERROR",
        });
      });
  };
}

export function getStudentDetailsCalendarFormerDispo(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_STUDENTS_DETAILS_CALENDAR_FORMER_DISPO_LOADING" });
    axios
      .post(`/api/students/${id.toString()}/calendar/formerDispo`, { options })
      .then((response) => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_CALENDAR_FORMER_DISPO_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_CALENDAR_FORMER_DISPO_ERROR",
        });
      });
  };
}

export function getStudentDetailsProgram(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_STUDENTS_DETAILS_PROGRAM_LOADING" });
    axios
      .get(`/api/students/${id}/program`)
      .then((response) => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_PROGRAM_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_PROGRAM_ERROR",
        });
      });
  };
}

export function getStudentDetailsDisponibility(id) {
  return (dispatch) => {
    dispatch({ type: "GET_STUDENTS_DETAILS_DISPONIBILITY_LOADING" });
    axios
      .get(`/api/students/${id}/availability`)
      .then((response) => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_DISPONIBILITY_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_DISPONIBILITY_ERROR",
        });
      });
  };
}

export function getStudentDetailsEventsDisponibility(id) {
  return (dispatch) => {
    dispatch({ type: "GET_STUDENTS_DETAILS_EVENTS_DISPONIBILITY_LOADING" });
    axios
      .get(`/api/students/${id}/eventsavailability`)
      .then((response) => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_EVENTS_DISPONIBILITY_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_EVENTS_DISPONIBILITY_ERROR",
        });
      });
  };
}

export function getStudentDetailsSearchSelectFormer(params, options) {
  return (dispatch, ...args) => {
    dispatch({ type: "GET_SELECT_FORMER_LOADING" });
    axios
      .get(
        `/api/students/${params.studentsid}/search/create/${params.formationsid}`
      )
      .then((response) => {
        dispatch({
          type: "GET_SELECT_FORMER_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_SELECT_FORMER_ERROR",
        });
      });
  };
}

export function getStudentDetailsSearchSelectFormerTime(params, options) {
  return (dispatch, ...args) => {
    dispatch({ type: "GET_SELECT_FORMER_TIME_LOADING" });
    axios
      .get(
        `/api/students/${params.studentsid}/search/create/${params.formationsid}/selectTime`
      )
      .then((response) => {
        dispatch({
          type: "GET_SELECT_FORMER_TIME_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_SELECT_FORMER_TIME_ERROR",
        });
      });
  };
}

export function getStudentDetailsTrainingsCreateSelectFormer(params, options) {
  return (dispatch, ...args) => {
    dispatch({
      type: "GET_STUDENT_DETAILS_TRAINING_CREATE_SELECT_FORMER_LOADING",
    });
    axios
      .post(
        `/api/students/${params.studentsid}/trainings/create/select/former`,
        options
      )
      .then((response) => {
        dispatch({
          type: "GET_STUDENT_DETAILS_TRAINING_CREATE_SELECT_FORMER_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENT_DETAILS_TRAINING_CREATE_SELECT_FORMER_ERROR",
        });
      });
  };
}

export function getStudentDetailsSearchSelectFormerFormer(params, options) {
  return (dispatch, ...args) => {
    dispatch({
      type: "GET_STUDENTS_DETAILS_SEARCH_SELECT_FORMER_FORMER_LOADING",
    });
    axios
      .post(
        `/api/students/${params.studentsid}/search/create/${params.formationsid}/former`,
        { params, options }
      )
      .then((response) => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_SEARCH_SELECT_FORMER_FORMER_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_SEARCH_SELECT_FORMER_FORMER_ERROR",
        });
      });
  };
}

export function getStudentDetailsDisponibilityUpdate(id, table) {
  return (dispatch) => {
    dispatch({ type: "GET_STUDENTS_DETAILS_DISPONIBILITY_UPDATE_LOADING" });
    axios
      .post(`/api/students/${id}/availability/update`, table)
      .then((response) => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_DISPONIBILITY_UPDATE_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_DISPONIBILITY_UPDATE_ERROR",
        });
      });
  };
}

// export function getStudentDetailsSearchDetails(id, searchid, options) {
//   return (dispatch) => {
//     dispatch({ type: "GET_STUDENTS_DETAILS_SEARCH_DETAILS_LOADING" });
//     console.log(id, searchid);
//     axios
//       .get(`/api/students/${id}/search/${searchid}`)
//       .then((response) => {
//         dispatch({
//           type: "GET_STUDENTS_DETAILS_SEARCH_DETAILS_SUCCESS",
//           payload: response.data,
//         });
//       })
//       .catch(() => {
//         dispatch({
//           type: "GET_STUDENTS_DETAILS_SEARCH_DETAILS_ERROR",
//         });
//       });
//   };
// }

// export function getStudentDetailsSearchCreate(id, options) {
//   return (dispatch) => {
//     dispatch({ type: "GET_FORMATIONS_LISTING_LOADING" });
//     axios
//       .get(`/api/students/${id}/search/create`)
//       .then((response) => {
//         dispatch({
//           type: "GET_FORMATIONS_LISTING_SUCCESS",
//           payload: response.data,
//         });
//       })
//       .catch(() => {
//         dispatch({
//           type: "GET_FORMATIONS_LISTING_ERROR",
//         });
//       });
//   };
// }

export function getStudentDetailsSearchCreate(id, options) {
  return (dispatch) => {
    dispatch({ type: "GET_STUDENTS_DETAILS_SEARCH_CREATE_LOADING" });
    axios
      .get(`/api/students/${id}/search/create`)
      .then((response) => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_SEARCH_CREATE_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_STUDENTS_DETAILS_SEARCH_CREATE_ERROR",
        });
      });
  };
}
