const defaultState = {
  graph: {
    error: false,
    loading: false,
    data: [],
  },
};

export default function layout(state = defaultState, action) {
  switch (action.type) {
    case "GET_DASHBOARD_LOADING":
      return {
        ...state,
        graph: { ...state.graph, loading: true, error: false },
      };
    case "GET_DASHBOARD_SUCCESS":
      return {
        ...state,
        graph: { ...state.graph, loading: false, data: action.payload },
      };
    case "GET_DASHBOARD_ERROR":
      return {
        ...state,
        graph: { ...state.graph, loading: false, error: true },
      };
    default:
      return state;
  }
}