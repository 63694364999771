import { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Year from "./Year";
import Month from "./Month";
import Week from "./Week";
import Day from "./Day";
import SelectorLittle from "../Form/SelectorLittle";
import { getStudentDetailsCalendarFormerId } from "../../store/actions/students";
import { connect } from "../../store";
import { withRouter } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  border-radius: 3px;
`;

const Content = styled.div`
  flex: 1;
  background: white;
  margin-top: 10px;
  padding: 15px;
  padding-left: calc(38px + 15px);
  box-sizing: border-box;
  position: relative;
`;

const Button = styled.button`
  flex: 1;
  margin-right: 2px;
  width: 150px;
`;

const Label = styled.span`
  font-weight: bold;
  right: 2%;
  top: 0%;
  position: absolute;
`;

const labelMonths = {
  0: "Janvier",
  1: "Février",
  2: "Mars",
  3: "Avril",
  4: "Mai",
  5: "Juin",
  6: "Juillet",
  7: "Aout",
  8: "Septembre",
  9: "Octobre",
  10: "Novembre",
  11: "Décembre",
};

const nextType = {
  week: "Semaine prochaine",
  day: "Jour suivant",
};

const actualType = {
  week: "Semaine actuelle",
  day: "Jour actuel",
};

const previousType = {
  week: "Semaine précédente",
  day: " Jour précédent",
};

function SwitchCalendarView({
  displayType,
  selectedDate,
  setSelectedDate,
  setDisplayType,
  setNonDispo,
  events,
  dispo,
  setDispo,
  program,
  selectId,
  formerId,
  nonDispo,
}) {
  switch (displayType) {
    case "day":
      return (
        <Day
          currentDate={selectedDate}
          type="day"
          displayCurrentTime
          events={events}
          dispo={dispo}
          program={program}
          selectId={selectId.params}
          popUpPosition="calc(50% - 250px)"
          formerId={formerId}
          nonDispo={nonDispo}
        >
          {console.log("selectedDate : ", selectedDate)}
        </Day>
      );
    case "week":
      return (
        <Week
          date={selectedDate}
          type="week"
          setCurrentDate={setSelectedDate}
          setCurrentView={setDisplayType}
          setNonDispo={setNonDispo}
          events={events}
          dispo={dispo}
          setDispo={setDispo}
          program={program}
          selectId={selectId.params}
          popUpPosition="400px"
          formerId={formerId}
        />
      );
    case "year":
      return (
        <Year
          currentdate={selectedDate}
          setCurrentDate={setSelectedDate}
          setCurrentView={setDisplayType}
          selectId={selectId.params}
        />
      );
    case "month":
    default:
      return (
        <Month
          currentDate={selectedDate}
          setCurrentDate={setSelectedDate}
          setCurrentView={setDisplayType}
        />
      );
  }
}

function getActionsByDisplayType(
  displayType,
  selectedDate,
  setSelectedDate,
  setNonDispo,
  getUnavailabilityByDate,
  selectId,
  formerId
) {
  switch (displayType) {
    case "day":
      return {
        prev: () => {
          const date = new Date(selectedDate);
          date.setDate(date.getDate() - 1);
          setSelectedDate(date);
          axios
            .post(
              `http://localhost:4000/students/${selectId.params.studentsid}/calendar/formerDispos`,
              {
                formerId: formerId,
                dates: date,
              }
            )
            .then((response) => {
              setNonDispo(getUnavailabilityByDate(response.data, date));
            });
        },
        next: () => {
          const date = new Date(selectedDate);
          date.setDate(date.getDate() + 1);
          setSelectedDate(date);
          axios
            .post(
              `http://localhost:4000/students/${selectId.params.studentsid}/calendar/formerDispos`,
              {
                formerId: formerId,
                dates: date,
              }
            )
            .then((response) => {
              setNonDispo(getUnavailabilityByDate(response.data, date));
            });
        },
      };
    case "week":
      return {
        prev: () => {
          const date = new Date(selectedDate);
          date.setDate(date.getDate() - 7);
          setSelectedDate(date);
        },
        next: () => {
          const date = new Date(selectedDate);
          date.setDate(date.getDate() + 7);
          setSelectedDate(date);
        },
      };
    case "year":
      return {
        prev: () => {
          const date = new Date(selectedDate);
          date.setUTCFullYear(date.getFullYear() - 1);
          setSelectedDate(date);
        },
        next: () => {
          const date = new Date(selectedDate);
          date.setUTCFullYear(date.getFullYear() + 1);
          setSelectedDate(date);
        },
      };
    case "month":
    default:
      return {
        prev: () => {
          const date = new Date(selectedDate);
          date.setMonth(date.getMonth() - 1);
          setSelectedDate(date);
        },
        next: () => {
          const date = new Date(selectedDate);
          date.setMonth(date.getMonth() + 1);
          setSelectedDate(date);
        },
      };
  }
}

function switchLabel(displayType, selectedDate) {
  switch (displayType) {
    case "day":
      return `
      ${labelMonths[selectedDate.getMonth()]} 
      ${selectedDate.getFullYear()}`;
    case "week":
      return `${
        labelMonths[selectedDate.getMonth()]
      } ${selectedDate.getFullYear()}`;
    case "year":
      return selectedDate.getFullYear();
    case "month":
    default:
      return `${
        labelMonths[selectedDate.getMonth()]
      } ${selectedDate.getFullYear()}`;
  }
}

function getUnavailabilityByDate(data, day) {
  let response = [];
  for (let index = 0; index < data.length; index++) {
    if (new Date(data[index].start).getDate() === day.getDate()) {
      if (
        (new Date(data[index].start).getHours() < 8 ||
          new Date(data[index].start).getHours() === 0) &&
        new Date(data[index].end).getHours() <= 22 &&
        new Date(data[index].end).getHours() !== 0
      ) {
        response.push({
          startDate: new Date(data[index].start).setHours(8),
          endDate: data[index].end,
          push: "1",
        });
      } else if (
        new Date(data[index].start).getHours() >= 8 &&
        new Date(data[index].start).getHours() !== 0 &&
        (new Date(data[index].end).getHours() > 22 ||
          new Date(data[index].end).getHours() === 0) &&
        new Date(data[index].start).getHours() < 22
      ) {
        response.push({
          startDate: data[index].start,
          endDate: new Date(data[index].end).setHours(22),
          push: "2",
        });
      } else if (
        (new Date(data[index].start).getHours() < 8 ||
          new Date(data[index].start).getHours() === 0) &&
        (new Date(data[index].end).getHours() > 22 ||
          new Date(data[index].end).getHours() === 0)
      ) {
        response.push({
          startDate: new Date(data[index].start).setHours(8),
          endDate: new Date(data[index].end).setHours(22),
          push: "3",
        });
      } else {
        if (new Date(data[index].start).getHours() < 22) {
          response.push({
            startDate: data[index].start,
            endDate: data[index].end,
            push: "4",
          });
        }
      }
    }
  }
  return response;
}

function Calendar({
  date = new Date(),
  events,
  weekDispo,
  dispo,
  setDispo,
  program,
  selectId,
  type,
  data,
  loading,
  getStudentDetailsCalendarFormerId,
}) {
  const [displayType, setDisplayType] = useState(type);
  const [selectedDate, setSelectedDate] = useState(date);
  const [formerId, setFormerId] = useState("");
  const [nonDispo, setNonDispo] = useState([]);
  const actions = getActionsByDisplayType(
    displayType,
    selectedDate,
    setSelectedDate,
    setNonDispo,
    getUnavailabilityByDate,
    selectId,
    formerId,
    setDisplayType
  );

  const handleDayDisplay = () => {
    setDisplayType("day");
    setDispo(dispo.data[selectedDate.getDay()]);
    axios
      .post(
        `http://localhost:4000/students/${selectId.params.studentsid}/calendar/formerDispos`,
        {
          formerId: formerId,
          dates: selectedDate,
        }
      )
      .then((response) => {
        setNonDispo(getUnavailabilityByDate(response.data, selectedDate));
      });
  };

  const handleWeekDisplay = () => {
    setDispo(weekDispo);
    setDisplayType("week");
  };

  const handleCurrentDay = () => {
    setSelectedDate(new Date());
    axios
      .post(
        `http://localhost:4000/students/${selectId.params.studentsid}/calendar/formerDispos`,
        {
          formerId: formerId,
          dates: selectedDate,
        }
      )
      .then((response) => {
        setNonDispo(getUnavailabilityByDate(response.data, selectedDate));
      });
  };

  useEffect(() => {
    getStudentDetailsCalendarFormerId(selectId.params.studentsid);
    console.log("calendar");
  }, [getStudentDetailsCalendarFormerId, selectId.params.studentsid]);

  return (
    <Container>
      {loading && <span>Loading</span>}
      {!loading && (
        <>
          <div style={{ display: "flex", width: "80%", flexDirection: "row" }}>
            <Button onClick={() => handleDayDisplay()}>Jour</Button>
            <Button onClick={() => handleWeekDisplay()}>Semaine</Button>
            <Button onClick={actions.prev}>{previousType[displayType]}</Button>
            <Button onClick={() => handleCurrentDay()}>
              {actualType[displayType]}
            </Button>
            <Button onClick={actions.next}>{nextType[displayType]}</Button>
            <input
              type="date"
              style={{ width: "150px" }}
              onChange={(e) => setSelectedDate(new Date(e.target.value))}
              value={`${selectedDate.getFullYear()}-${String(
                selectedDate.getMonth() + 1
              ).padStart(2, "0")}-${String(selectedDate.getDate()).padStart(
                2,
                "0"
              )}`}
            />
            <SelectorLittle
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setFormerId(data[e.target.value]);
              }}
              data={["Selectionner une formation"].concat(Object.keys(data))}
            ></SelectorLittle>
            <Label>{switchLabel(displayType, selectedDate)}</Label>
          </div>
          <Content>
            <SwitchCalendarView
              events={events}
              dispo={dispo}
              setDispo={setDispo}
              program={program}
              displayType={displayType}
              selectedDate={selectedDate}
              setDisplayType={setDisplayType}
              setSelectedDate={setSelectedDate}
              setNonDispo={setNonDispo}
              selectId={selectId}
              formerId={formerId}
              nonDispo={nonDispo}
            />
          </Content>
        </>
      )}
    </Container>
  );
}

export default withRouter(
  connect(
    (state) => ({
      data: state.students.details.search.formerId.data,
      loading: state.students.details.search.formerId.loading,
      error: state.students.details.search.formerId.error,
      actionWithState: getStudentDetailsCalendarFormerId.bind(state),
    }),
    { getStudentDetailsCalendarFormerId }
  )(Calendar)
);

