import { Route, Switch } from "react-router";
import FormersStudentsListing from "./Listing";
import FormersStudentsDetails from "./Detail";

function FormerStudents() {
  return (
    <Switch>
      <Route
        exact
        path="/formers/:formersid/students"
        component={FormersStudentsListing}
      />
      <Route
        strict
        path="/formers/:formersid/students/:studentsid"
        component={FormersStudentsDetails}
      />
    </Switch>
  );
}

export default FormerStudents;
