import { connect } from "../../../../../../../store";
import { useEffect, useState } from "react";
import { Table, Td, Th, Tr, Tj, Row } from "../../../../../../../Common/Table";
import User from "../../../../../../../Common/User";
import Page from "../../../../../../../Layout/Page";
import {
  getStudentDetailsSearchSelectFormerFormer,
  getStudentDetailsTrainingsCreateSelectFormer,
} from "../../../../../../../store/actions/students";
import SearchBar from "../../../../../../../Layout/SearchBar";
import { withRouter } from "react-router";
import InputTime from "../../../../../../../Common/Form/InputTime";

const sessionIn = {};
let finalData = [];

function timeMatch(monthTime, dataDispo, straightHours, total) {
  const letters = ["a", "b", "c", "d"];
  let time = 99;
  let note = 0;

  letters.forEach((letter, i) => {
    if (monthTime) {
      monthTime[letter]?.map((day, indexWeek) => {
        let compteur = 0;
        return day?.map((item, indexDay) => {
          if (item === false && dataDispo[indexWeek][indexDay] === false) {
            compteur = compteur + 1;
            if (compteur < straightHours + 1) {
              note = note + 1;
              if (note === total) {
                time = i * 7 + indexWeek + 1;
              }
            }
          }

          return time;
        });
      });
    }
  });
  return { time: time, note: note };
}

function SelectFormer({
  data,
  match,
  getStudentDetailsTrainingsCreateSelectFormer,
  getStudentDetailsSearchSelectFormerFormer,
  history,
}) {
  const [liste, setListe] = useState([]);
  const [authorized, setAuthorized] = useState(false);
  const [straightHours, setStraightHours] = useState(2);
  const [change, setChange] = useState(0);
  const [total, setTotal] = useState(12);

  const handleClick = () => {
    setChange(change + 1);
    console.log("ok");
  };

  const handleSelect = (item) => {
    getStudentDetailsSearchSelectFormerFormer(
      {
        studentsid: match.params.studentsid,
        formationsid: match.params.formationsid,
      },
      { formerid: item.id }
    );
    history.go(-2);
  };

  useEffect(() => {
    setListe([]);
    setAuthorized(false);
    getStudentDetailsTrainingsCreateSelectFormer(
      {
        studentsid: match.params.studentsid,
        formationsid: match.params.formationsid,
      },
      {
        studentsid: match.params.studentsid,
        formationsid: match.params.formationsid,
      }
    );
    finalData.length = 0;
    setListe(finalData);
  }, [
    getStudentDetailsTrainingsCreateSelectFormer,
    match.params.studentsid,
    match.params.formationsid,
  ]);

  useEffect(() => {
    if (data.data) {
      setListe([]);
      finalData.length = 0;
      setListe(finalData);
      data.data.map((item) => {
        if (data.dataTime[item.id]) {
          data.dataTime[item.id]["lastname"] = item.lastname;
          data.dataTime[item.id]["firstname"] = item.firstname;
          data.dataTime[item.id]["id"] = item.id;
          sessionIn[`${item.id}`] = data.dataTime[item.id];
          finalData.push({
            lastname: `${item.lastname}`,
            firstname: `${item.firstname}`,
            id: `${item.id}`,
            data: timeMatch(
              sessionIn[`${item.id}`],
              data.dataDispo,
              straightHours,
              total
            ),
          });
        }
        return finalData;
      });
      setListe(finalData);
      if (liste.length > 0) {
        setAuthorized(true);
      }
    }
  }, [data, liste, straightHours, total]);

  return (
    <>
      <Page>
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <SearchBar style={{ display: "flex", flex: "1" }}></SearchBar>
          <InputTime
            style={{ display: "flex", flex: "1" }}
            label="Total d'heures"
            value={total}
            onChange={(e) => setTotal(e.target.value)}
            onClick={handleClick}
            placeholder="Nombre d'heures de la formation"
          ></InputTime>
          <InputTime
            style={{ display: "flex", flex: "1" }}
            onChange={(e) => setStraightHours(e.target.value)}
            onClick={handleClick}
            label="Heures à la suite"
            value={straightHours}
            placeholder="Nombre d'heures à la suite"
          ></InputTime>
        </Row>
        <Table>
          <thead>
            <tr>
              <Th>Nom</Th>
              <Th style={{ textAlign: "center" }}>Sessions</Th>
              <Th style={{ textAlign: "right" }}>Durée</Th>
            </tr>
          </thead>
          <tbody>
            {!authorized && (
              <Tr>
                <Tj colspan={3}>Loading</Tj>
                <Tj></Tj>
                <Tj></Tj>
              </Tr>
            )}
            {authorized &&
              liste
                .sort((a, b) => {
                  return a.data.time - b.data.time;
                })
                ?.map((item) => (
                  <Tr onClick={() => handleSelect(item)} key={`${item.id}`}>
                    <Td>
                      <User name={item.firstname} lastname={item.lastname} />
                    </Td>
                    <Td style={{ textAlign: "center" }}>{item.data.note}</Td>
                    {item.data.time === 99 && (
                      <Td style={{ textAlign: "right" }}>+ 1 mois</Td>
                    )}
                    {!(item.data.time === 99) && (
                      <Td style={{ textAlign: "right" }}>{item.data.time}</Td>
                    )}
                  </Tr>
                ))}
          </tbody>
        </Table>
      </Page>
    </>
  );
}

export default withRouter(
  connect(
    (state) => ({
      data: state.students.details.search.resultat.data,
      loading: state.students.details.search.resultat.loading,
      error: state.students.details.search.resultat.error,
      dataFormer: state.students.details.search.former.data,
    }),
    {
      getStudentDetailsTrainingsCreateSelectFormer,
      getStudentDetailsSearchSelectFormerFormer,
    }
  )(SelectFormer)
);
