import { useState, useEffect } from "react";
import SelectorForm from "../../Form/SelectorForm";
import { getStudentDetailsCalendarCreate } from "../../../store/actions/students";
import { connect } from "../../../store";
import {
  Container,
  DayNumber,
  DayLabel,
  Content,
  TimeGrid,
  TimeGridLine,
  TimeGridLineEmpty,
  EventContainer,
  TopContainer,
  CurrentTimeContainer,
  ContentContainer,
  ContentContainer1,
  PopUpContainer,
  Button,
  RowContainer,
  NonDispoContainer,
  DispoContainer,
} from "./Styled";

const labelDays = {
  1: "Lundi",
  2: "Mardi",
  3: "Mercredi",
  4: "Jeudi",
  5: "Vendredi",
  6: "Samedi",
  7: "Dimanche",
};

const getCurrentDay = function (date) {
  return date.getDate();
};

const getCurrentLabel = function (date) {
  const day = date.getDay();
  return labelDays[day === 0 ? 7 : day];
};

const getPopUpHour = function (e) {
  const pourcentage = 7.14 / 100;
  const borders = e.currentTarget.getBoundingClientRect();
  const height = borders.height - pourcentage * borders.height;
  const range = height / 13;
  const actualRange = Math.trunc((e.clientY - borders.top - 10) / range);
  const startTime = `${String(actualRange + 8).padStart(2, "0")}:00`;
  const endTime = `${String(actualRange + 9).padStart(2, "0")}:00`;
  const result = [startTime, endTime];
  return result;
};

const getTopPosition = function (range, event) {
  const refStart = new Date(range[0]).getTime();
  const refEnd = new Date(range[1]).getTime();
  const refCurrent = new Date(event.startDate).getTime();
  const totalHeight = refEnd - refStart;
  const start = refCurrent - refStart;
  const calcul = (100 * start) / totalHeight;
  const result = calcul.toString() + "%";
  return result;
};

const getTopePosition = function (range, event) {
  const refStart = new Date(range[0]).getTime();
  const refEnd = new Date(range[1]).getTime();
  const refCurrent = new Date(event.startDate).getTime();
  const totalHeight = refEnd - refStart;
  const start = refCurrent - refStart;
  const calcul = (100 * start) / totalHeight;
  const result = calcul.toString() + "%";
  return result;
};

const getToPosition = function (range, event) {
  const refStart = new Date(range[0]).getTime();
  const refEnd = new Date(range[1]).getTime();
  const refCurrent = new Date(event.startDate).getTime();
  const totalHeight = refEnd - refStart;
  const start = refCurrent - refStart;
  const calcul = (100 * start) / totalHeight;
  const result = calcul.toString() + "%";
  return result;
};

const getHeight = function (range, event) {
  const refStart = new Date(range[0]).getTime();
  const refEnd = new Date(range[1]).getTime();
  const refCurrentStart = new Date(event.startDate).getTime();
  const refCurrentEnd = new Date(event.endDate).getTime();
  const eventHeight = refCurrentEnd - refCurrentStart;
  const totalHeight = refEnd - refStart;
  const calcul = (100 * eventHeight) / totalHeight;
  const result = calcul.toString() + "%";
  return result;
};

const getTimeGrid = function (date, range) {
  const startDate = new Date(date);
  const endDate = new Date(date);
  startDate.setHours(8, 0, 0);
  endDate.setHours(22, 0, 0);
  const numberOfLine = (endDate.getTime() - startDate.getTime()) / range;
  const lines = [...new Array(numberOfLine)].map((_, index) => {
    return new Date(startDate.getTime() + range * index);
  });
  return { startDate, endDate, numberOfLine, lines };
};

function Day({
  currentDate = new Date(),
  displayDayNumber = true,
  displayTime = true,
  selectId,
  displayCurrentTime = false,
  type,
  timeGridDisplay = true,
  displayDayLabel = true,
  events = [],
  dispo,
  program,
  popUpPosition,
  nonDispo,
  getStudentDetailsCalendarCreate,
  formerId,
}) {
  const [currentTimeContainer, setCurrentTimeContainer] = useState(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      new Date().getHours(),
      new Date().getMinutes()
    )
  );

  const [popUpOn, setPopUpOn] = useState(false);
  const [date, setDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(currentDate.getDate()).padStart(2, "0")}`
  );

  const [startTime, setStartTime] = useState("08:00");
  const [endTime, setEndTime] = useState("09:00");
  const [programName, setProgramName] = useState("");

  const [interData, setInterData] = useState({
    lastname: "",
    firstname: "",
    formerId: "",
    name: "",
    programId: "",
  });

  const handleChangeInt = (path, value) => {
    setInterData({ ...interData, [path]: value });
  };

  const handleSave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (parseInt(startTime.substring(0, 2), 10) < 12) {
      getStudentDetailsCalendarCreate(selectId.studentsid, {
        start: startTime,
        end: endTime,
        date: date,
        programName: programName,
        slot: "morning",
      });
    } else {
      getStudentDetailsCalendarCreate(selectId.studentsid, {
        start: startTime,
        end: endTime,
        date: date,
        programName: programName,
        slot: "afternoon",
      });
    }
    setPopUpOn(false);
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setPopUpOn(false);
  };

  const [onDay, setOnDay] = useState(false);
  const eventDispo = [];
  const eventsDispo = [];
  for (let index = 0; index < dispo.length; index++) {
    eventDispo.push({
      startDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        dispo[index],
        0,
        0
      ),
      endDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        dispo[index] + 1,
        0,
        0
      ),
    });
  }

  if (eventDispo.length > 0) {
    let x = eventDispo[0].startDate;
    let y = eventDispo[0].endDate;
    for (let index = 0; index < eventDispo.length - 1; index++) {
      if (
        eventDispo[index].endDate.getHours() !==
        eventDispo[index + 1].startDate.getHours()
      ) {
        eventsDispo.push({ startDate: x, endDate: y });
        x = eventDispo[index + 1].startDate;
        y = eventDispo[index + 1].endDate;
      } else {
        y = eventDispo[index + 1].endDate;
      }
    }
    eventsDispo.push({ startDate: x, endDate: y });
  }

  // useEffect(() => {
  //   axios
  //     .get(`http://localhost:4000/students/${selectId.studentsid}/availability`)
  //     .then((response) => {
  //       console.log("axios : ", response.data);
  //     });
  // }, [selectId.studentsid]);

  useEffect(() => {
    window.setInterval(() => {
      setCurrentTimeContainer(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          new Date().getHours(),
          new Date().getMinutes()
        )
      );
    }, 60000);

    if (type === "week") {
      setOnDay(false);
    }

    if (type === "day") {
      setOnDay(true);
    }

    if (program) {
      setProgramName(program[0]);
    }
  }, [program, type, currentDate]);

  const currentDay = getCurrentDay(currentDate);
  const currentLabel = getCurrentLabel(currentDate);
  const range = 60000 * 60;
  const grid = getTimeGrid(currentDate, range);

  const now = {
    startDate: currentTimeContainer,
  };

  return (
    <>
      <Container test={displayTime === true}>
        <TopContainer>
          {displayDayNumber && (
            <DayNumber
              className={
                currentDay === new Date().getDate() &&
                currentDate.getMonth() === new Date().getMonth() &&
                currentDate.getFullYear() === new Date().getFullYear()
                  ? "active"
                  : ""
              }
            >
              {currentDay}
            </DayNumber>
          )}
          {displayDayLabel && (
            <DayLabel
              className={
                currentDay === new Date().getDate() &&
                currentDate.getMonth() === new Date().getMonth()
                  ? "active"
                  : ""
              }
            >
              {currentLabel}
            </DayLabel>
          )}
        </TopContainer>
        {timeGridDisplay && (
          <Content>
            <TimeGrid
              onClick={(e) => {
                const rect = e.currentTarget.getBoundingClientRect();
                console.log(e.clientY - rect.top - 10);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {grid.lines.map((line, index) => (
                <TimeGridLine
                  key={index}
                  style={{
                    top: getTopPosition([grid.startDate, grid.endDate], {
                      startDate: line,
                    }),
                  }}
                >
                  <span>
                    {displayTime &&
                      line.toLocaleString("fr-FR", {
                        hour: "numeric",
                        minute: "numeric",
                      })}
                  </span>
                  <TimeGridLineEmpty />
                </TimeGridLine>
              ))}
            </TimeGrid>
            <>
              {events
                .filter((item) => {
                  return (
                    new Date(item.startDate).getTime() >
                      new Date(grid.startDate).getTime() &&
                    new Date(item.endDate).getTime() <
                      new Date(grid.endDate).getTime()
                  );
                })
                .sort((a, b) => {
                  return (
                    new Date(a.startDate).getTime() -
                    new Date(b.startDate).getTime() +
                    (new Date(a.endDate).getTime() -
                      new Date(b.endDate).getTime())
                  );
                })
                .map((event, index) => (
                  <EventContainer
                    key={index}
                    style={{
                      zIndex: index + 12,
                      top: `calc(${getTopePosition(
                        [grid.startDate, grid.endDate],
                        event
                      )} + (21px / 2))`,
                      height: getHeight([grid.startDate, grid.endDate], event),
                    }}
                    full={type !== "day"}
                  >
                    <ContentContainer>{event.label}</ContentContainer>
                    <ContentContainer1>
                      {`${new Date(event.startDate).toLocaleString("fr-FR", {
                        hour: "numeric",
                        minute: "numeric",
                      })} - ${new Date(event.endDate).toLocaleString("fr-FR", {
                        hour: "numeric",
                        minute: "numeric",
                      })}`}
                    </ContentContainer1>
                  </EventContainer>
                ))}
            </>
            <>
              {nonDispo &&
                nonDispo.map((event, index) => (
                  <NonDispoContainer
                    key={index}
                    style={{
                      zIndex: 11,
                      top: `calc(${getTopePosition(
                        [grid.startDate, grid.endDate],
                        event
                      )} + (21px / 2))`,
                      height: getHeight([grid.startDate, grid.endDate], event),
                    }}
                    full={type !== "day"}
                  ></NonDispoContainer>
                ))}
            </>
            <>
              {dispo &&
                eventsDispo.map((event, index) => (
                  <DispoContainer
                    key={index}
                    style={{
                      zIndex: 10,
                      top: `calc(${getTopePosition(
                        [grid.startDate, grid.endDate],
                        event
                      )} + (21px / 2))`,
                      height: getHeight([grid.startDate, grid.endDate], event),
                    }}
                    full={type !== "day"}
                  ></DispoContainer>
                ))}
            </>
            {onDay && popUpOn && (
              <PopUpContainer style={{ left: popUpPosition }}>
                <SelectorForm
                  label="Selectionner une formation"
                  onChange={(e) => {
                    console.log("onChange : ", e.target.value);
                    e.preventDefault();
                    e.stopPropagation();
                    handleChangeInt("name", e.target.value);
                    handleChangeInt("programId", e.target.value);
                    setProgramName(e.target.value);
                  }}
                  data={program}
                ></SelectorForm>
                <RowContainer>
                  <input
                    type="date"
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDate(
                        `${new Date(e.target.value).getFullYear()}-${String(
                          new Date(e.target.value).getMonth() + 1
                        ).padStart(2, "0")}-${String(
                          new Date(e.target.value).getDate()
                        ).padStart(2, "0")}`
                      );
                    }}
                    value={date}
                    style={{ width: "150px", flex: 1 }}
                  />
                  <input
                    type="time"
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setStartTime(e.target.value);
                      // console.log(parseInt(e.target.value.substring(0, 2), 10));
                    }}
                    value={startTime}
                    style={{ width: "150px", flex: 1 }}
                  />
                  <input
                    type="time"
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setEndTime(e.target.value);
                      // console.log(e.target.value);
                    }}
                    value={endTime}
                    style={{ width: "150px", flex: 1 }}
                  />
                </RowContainer>
                <RowContainer>
                  <Button
                    onClick={(e) => {
                      handleClose(e);
                    }}
                    style={{ marginRight: "5px" }}
                  >
                    Annuler
                  </Button>
                  <Button
                    onClick={(e) => {
                      handleSave(e);
                    }}
                    style={{ marginLeft: "5px" }}
                  >
                    Sauvegarder
                  </Button>
                </RowContainer>
              </PopUpContainer>
            )}
          </Content>
        )}
      </Container>
      {displayCurrentTime && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: `calc(100% - 80px)`,
            left: "0px",
            top: "65px",
            zIndex: 20,
          }}
          onClick={(e) => {
            setPopUpOn(true);
            setPopUpOn(!popUpOn);
            setStartTime(getPopUpHour(e)[0]);
            setEndTime(getPopUpHour(e)[1]);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CurrentTimeContainer
            style={{
              top: `calc(${getToPosition(
                [grid.startDate, grid.endDate],
                now
              )} + (21px / 2 ))`,
            }}
            data-time={currentTimeContainer.toLocaleString("fr-fr", {
              hour: "numeric",
              minute: "numeric",
            })}
          ></CurrentTimeContainer>
        </div>
      )}
    </>
  );
}

export default connect(
  (state) => ({
    data: state.students.details.calendarCreate.data,
    loading: state.students.details.calendarCreate.loading,
    error: state.students.details.calendarCreate.error,
  }),
  { getStudentDetailsCalendarCreate }
)(Day);
