import Page from "../../Layout/Page";
import Image from "../../Common/Image";

function Dashboard() {
  return (
    <Page>
      {/* <Container>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
        suscipit enim vitae justo tempor, vel lobortis libero rhoncus. Sed vel
        tellus posuere, vehicula dolor sit amet, malesuada dolor. Nunc sed nulla
        sagittis, vestibulum risus laoreet, lacinia orci. Ut gravida lobortis
        velit a sodales. Nam laoreet in ipsum eu consequat. Sed vitae luctus
        diam. Sed ullamcorper interdum tortor, sodales placerat ante efficitur
        in. Suspendisse aliquet tortor non nisi malesuada, eu pharetra sem
        fringilla. Pellentesque eget purus at nisi posuere bibendum quis non
        ante. Aliquam erat volutpat. Nullam lacinia arcu eu libero fringilla,
        sed rhoncus mi placerat. In hac habitasse platea dictumst. Donec
        condimentum, lacus eu fringilla mollis, magna metus ultricies lorem, at
        aliquam ligula orci non est. Curabitur ut risus dolor. Nullam et erat
        justo. Nam a tellus mollis, feugiat nunc commodo, sollicitudin metus.
        Suspendisse faucibus eget tortor eu pretium. Ut id est orci. Curabitur
        ut ligula condimentum, molestie ipsum ac, porttitor neque. Nullam
        consequat, purus eu condimentum interdum, urna lectus rutrum lorem, vel
        maximus quam orci euismod purus. Suspendisse consectetur, lacus eu
        egestas aliquet, orci justo sodales risus, at lacinia massa risus nec
        odio. Nullam in felis vehicula, ultricies diam ac, volutpat justo. Ut
        pretium ipsum at euismod sodales. Praesent porttitor blandit congue. Sed
        id sollicitudin risus, ac laoreet ligula. Integer viverra ullamcorper
        pulvinar. Cras ultricies congue semper. Donec libero lectus, porttitor
        eu condimentum id, eleifend ut neque. Morbi accumsan magna vel bibendum
        vulputate. Duis pellentesque erat sit amet dolor accumsan, tincidunt
        vulputate leo aliquet. Aliquam consectetur ultricies turpis, tincidunt
        laoreet nibh posuere vel. Sed laoreet orci vitae libero rhoncus
        imperdiet. Vivamus malesuada ut risus et cursus. Integer nisi neque,
        facilisis eget facilisis quis, porta ac quam. Etiam elit lacus,
        ultricies sit amet scelerisque id, feugiat vel arcu. Nam quis rutrum
        ipsum. Etiam nec vehicula lectus. Ut urna nulla, volutpat et interdum
        sit amet, auctor vel ex. Sed luctus condimentum erat, quis condimentum
        elit facilisis id. Aenean sit amet imperdiet mauris. Phasellus pharetra
        pretium tortor at facilisis. Etiam dictum, nunc nec venenatis viverra,
        tellus quam tincidunt diam, eget molestie nisl erat nec leo. Curabitur
        egestas turpis eget ex interdum placerat. Cras vestibulum ligula sed
        rutrum bibendum. Vivamus lectus libero, suscipit facilisis hendrerit at,
        pulvinar nec arcu. Sed blandit erat non tristique vulputate. Nulla a
        consectetur sapien. Vestibulum elementum libero eu nunc dignissim, et
        pretium nisi rhoncus. Sed eget felis massa. Morbi dapibus quam ligula, a
        tincidunt nunc sodales nec. Pellentesque a sem scelerisque, condimentum
        nunc in, iaculis massa. Maecenas aliquam finibus pretium. Donec auctor
        libero id nibh interdum, sed suscipit lorem faucibus. Vestibulum dapibus
        massa risus, vel egestas sem faucibus sit amet. Nulla consectetur purus
        mauris, a malesuada eros luctus eget. Praesent ultricies metus mauris,
        vel elementum nisl finibus at. Orci varius natoque penatibus et magnis
        dis parturient montes, nascetur ridiculus mus. Mauris hendrerit in felis
        eu lobortis. Aenean vitae lobortis justo. Aliquam risus leo, dictum a
        dapibus quis, efficitur id urna. Aenean non dolor et sapien suscipit
        cursus nec vitae magna. Nunc feugiat placerat erat. Donec non porta
        ligula. Etiam orci diam, malesuada non augue facilisis, convallis
        dapibus urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Maecenas suscipit enim vitae justo tempor, vel lobortis libero rhoncus.
        Sed vel tellus posuere, vehicula dolor sit amet, malesuada dolor. Nunc
        sed nulla sagittis, vestibulum risus laoreet, lacinia orci. Ut gravida
        lobortis velit a sodales. Nam laoreet in ipsum eu consequat. Sed vitae
        luctus diam. Sed ullamcorper interdum tortor, sodales placerat ante
        efficitur in. Suspendisse aliquet tortor non nisi malesuada, eu pharetra
        sem fringilla. Pellentesque eget purus at nisi posuere bibendum quis non
        ante. Aliquam erat volutpat. Nullam lacinia arcu eu libero fringilla,
        sed rhoncus mi placerat. In hac habitasse platea dictumst. Donec
        condimentum, lacus eu fringilla mollis, magna metus ultricies lorem, at
        aliquam ligula orci non est.
      </Container> */}
      <Image></Image>
    </Page>
  );
}

export default Dashboard;
