import { useEffect } from "react";
import CreateButton from "../../../../../Common/CreateButton";
import Icon from "../../../../../Common/Icon";
import { getStudentDetailsSearch } from "../../../../../store/actions/students";
import { connect } from "../../../../../store";
import { Table, Td, Th, Tr } from "../../../../../Common/Table";
import Item from "../../../../../Common/Item";
import Page from "../../../../../Layout/Page";
import { withRouter } from "react-router-dom";

function StudentsSearchListing({
  match,
  getStudentDetailsSearch,
  data,
  loading,
}) {
  useEffect(() => {
    getStudentDetailsSearch(match.params.studentsid);
  }, [getStudentDetailsSearch, match.params.studentsid]);

  return (
    <>
      <Page>
        <Table>
          <thead>
            <tr>
              <Th>Formation</Th>
              <Th style={{ textAlign: "right" }}></Th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <Tr>
                <Td>Loading</Td>
                <Td style={{ textAlign: "right" }}></Td>
              </Tr>
            )}
            {!loading &&
              data.map((item, index) => (
                <Tr
                  key={index}
                  // onClick={() =>
                  //   history.push(
                  //     `/students/${match.params.studentsid}/search/${item.parentId}`
                  //   )
                  // }
                >
                  <Td>
                    <Item label={item.name}></Item>
                  </Td>
                  <Td style={{ textAlign: "right" }}></Td>
                </Tr>
              ))}
            {console.log(data)}
          </tbody>
        </Table>
        <CreateButton to={`/students/${match.params.studentsid}/search/create`}>
          <Icon name="add" />
        </CreateButton>
      </Page>
    </>
  );
}

export default withRouter(
  connect(
    (state) => ({
      data: state.students.details.search.listing.data,
      loading: state.students.details.search.listing.loading,
      error: state.students.details.search.listing.error,
      actionWithState: getStudentDetailsSearch.bind(state),
    }),
    { getStudentDetailsSearch }
  )(StudentsSearchListing)
);
