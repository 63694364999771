import styled from "styled-components";
import axios from "axios";
import { getStudentDetailsCalendarFormerDispo } from "../../../store/actions/students";
import Day from "../Day";
import { connect } from "../../../store";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
`;

function getUnavailabilityByDate(data, day) {
  let response = [];
  for (let index = 0; index < data.length; index++) {
    if (new Date(data[index].start).getDate() === day.date.getDate()) {
      if (
        (new Date(data[index].start).getHours() < 8 ||
          new Date(data[index].start).getHours() === 0) &&
        new Date(data[index].end).getHours() <= 22 &&
        new Date(data[index].end).getHours() !== 0
      ) {
        response.push({
          startDate: new Date(data[index].start).setHours(8),
          endDate: data[index].end,
          push: "1",
        });
      } else if (
        new Date(data[index].start).getHours() >= 8 &&
        new Date(data[index].start).getHours() !== 0 &&
        (new Date(data[index].end).getHours() > 22 ||
          new Date(data[index].end).getHours() === 0) &&
        new Date(data[index].start).getHours() < 22
      ) {
        response.push({
          startDate: data[index].start,
          endDate: new Date(data[index].end).setHours(22),
          push: "2",
        });
      } else if (
        (new Date(data[index].start).getHours() < 8 ||
          new Date(data[index].start).getHours() === 0) &&
        (new Date(data[index].end).getHours() > 22 ||
          new Date(data[index].end).getHours() === 0)
      ) {
        // console.log(
        //   "else if : ",
        //   new Date(data[index].start).getHours(),
        //   new Date(data[index].end).getHours()
        // );
        response.push({
          startDate: new Date(data[index].start).setHours(8),
          endDate: new Date(data[index].end).setHours(22),
          push: "3",
        });
      } else {
        if (new Date(data[index].start).getHours() < 22) {
          response.push({
            startDate: data[index].start,
            endDate: data[index].end,
            push: "4",
          });
        }
      }
    }
  }
  return response;
}

function openDetail(
  day,
  setCurrentDate,
  setCurrentView,
  setNonDispo,
  nonDispo,
  dispo,
  setDispo,
  getUnavailabilityByDate,
  selectId,
  formerId,
  e
) {
  e.preventDefault();
  e.stopPropagation();
  setCurrentView("day");
  setCurrentDate(day.date);
  axios
    .post(
      `https://www.api.formationaccess.fr/students/${selectId.studentsid}/calendar/formerDispos`,
      {
        formerId: formerId,
        dates: day.date,
      }
    )
    .then((response) => {
      setNonDispo(getUnavailabilityByDate(response.data, day));
    });
  setDispo(dispo.data[day.date.getDay()]);
}

function Week({
  date = new Date(),
  setCurrentDate,
  setCurrentView,
  setNonDispo,
  nonDispo,
  events,
  dispo,
  setDispo,
  selectId,
  popUpPosition,
  loading,
  data,
  getStudentDetailsCalendarFormerDispo,
  formerId,
}) {
  const currentIndex = date.getDay();
  const prevDays = [...new Array(currentIndex)]
    .map((_, i) => {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() - i);
      return { id: newDate.getTime(), date: newDate, day: newDate.getDay() };
    })
    .reverse();

  const nextDays = [...new Array(7 - currentIndex)].map((_, i) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + (i + 1));
    return { id: newDate.getTime(), date: newDate, day: newDate.getDay() };
  });
  const days = [...prevDays, ...nextDays];

  useEffect(() => {
    const currentIndex = date.getDay();
  const prevDays = [...new Array(currentIndex)]
    .map((_, i) => {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() - i);
      return { id: newDate.getTime(), date: newDate, day: newDate.getDay() };
    })
    .reverse();

  const nextDays = [...new Array(7 - currentIndex)].map((_, i) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + (i + 1));
    return { id: newDate.getTime(), date: newDate, day: newDate.getDay() };
  });
  const days = [...prevDays, ...nextDays];
    console.log("week");
    getStudentDetailsCalendarFormerDispo(selectId.toString(), {
      formerId: formerId,
      dates: days,
    });
  }, [getStudentDetailsCalendarFormerDispo, selectId, formerId, date]);

  return (
    <Container>
      {loading && <span>Loading</span>}
      {!loading && (
        <>
          {days.map((day, index) => (
            <Content
              key={day.id}
              onClick={(e) =>
                // (e) => console.log("day : ", day)
                openDetail(
                  day,
                  setCurrentDate,
                  setCurrentView,
                  setNonDispo,
                  nonDispo,
                  dispo,
                  setDispo,
                  getUnavailabilityByDate,
                  selectId,
                  formerId,
                  e
                )
              }
            >
              {/* <DayNumber>{daysMap[index]["date"]}</DayNumber>
            <DayLabel className={day.id === currentIndex ? "active" : ""}>
              {labelDays[index + 1]}
            </DayLabel> */}
              <Day
                currentDate={day.date}
                displayTime={index === 0}
                displayCurrentTime={index === 0}
                type="week"
                onDay={false}
                events={events}
                dispo={dispo.data[day.date.getDay()]}
                selectId={selectId}
                popUpPosition={popUpPosition}
                // nonDispo={data?.map((item) => {
                //   if (new Date(item.start).getDate() === day.date.getDate()) {
                //     return item;
                //   }
                // })}
                nonDispo={getUnavailabilityByDate(data, day)}
                // nonDispo={nonDispo}
                formerId={formerId}
              ></Day>
              {/* {console.log(formerId)}
              {console.log("day : ", day)} */}
            </Content>
          ))}
        </>
      )}
    </Container>
  );
}

export default withRouter(
  connect(
    (state) => ({
      data: state.students.details.search.formerDispo.data,
      loading: state.students.details.search.formerDispo.loading,
      error: state.students.details.search.formerDispo.error,
      actionWithState: getStudentDetailsCalendarFormerDispo.bind(state),
    }),
    { getStudentDetailsCalendarFormerDispo }
  )(Week)
);

// function Week() {
//   const daysMap = [...new Array(7)].map((_, index) => ({
//     id: index + 1,
//     label: `${index + 1}`,
//   }));
//   console.log(daysMap[0]);
//   console.log(daysMap, [...new Array(7)]);

//   return (
//     <Container>
//       {daysMap.map((day, index) => (
//         <Day displayTime={index === 0} />
//       ))}
//     </Container>
//   );
// }

// export default Week;

// function Day({ date = new Date() }) {
//   const currentDay = getCurrentDay(date);
//   console.log(currentDay, getCurrentLabel(date), Object.values(labelDays));
//   return (
//     <div>
//       {Object.values(labelDays).map((label) => (
//         <div>{label}</div>
//       ))}
//     </div>
//   );
// }
