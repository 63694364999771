import axios from "../../../api";

export function getAccountCreateAllowed() {
  return (dispatch) => {
    dispatch({ type: "GET_ACCOUNT_CREATE_ALLOWED_LOADING" });
    axios
      .get("/api/account/create")
      .then((response) => {
        dispatch({
          type: "GET_ACCOUNT_CREATE_ALLOWED_SUCCESS",
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "GET_ACCOUNT_CREATE_ALLOWED_ERROR",
        });
      });
  };
}
