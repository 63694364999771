const dashboard = {
  title: "Tableau de bord",
  navItems: [{ id: "uri-dashboard", path: "/", label: "Main" }],
};

const formersListing = {
  title: "Formateurs",
  navItems: [{ id: "uri-formers", path: "/formers", label: "Liste" }],
};

const formersDetails = {
  title: "Formateurs",
  navItems: [
    {
      id: "uri-formers-details",
      path: "/formers/:formersid",
      label: "Détails",
      exact: true,
    },
    {
      id: "uri-formers-details-recherche",
      path: "/formers/:formersid/search",
      label: "Formations",
    },
    {
      id: "uri-formers-details-calendar",
      path: "/formers/:formersid/calendar",
      label: "Calendrier",
    },
    {
      id: "uri-formers-details-students",
      path: "/formers/:formersid/students",
      label: "Etudiants",
    },
  ],
};

const formersDetailsSearchCreate = {
  title: "Formateurs",
  navItems: [
    {
      id: "uri-formers-details-recherche-create",
      path: "/formers/:formersid/search/create",
      label: "Ajouter une formation",
    },
  ],
};

const formersDetailsStudentsDetails = {
  title: "Etudiants",
  navItems: [
    {
      id: "uri-formers-details-students-detail",
      path: "/formers/:formersid/students/:studentsid",
      label: "Detail",
    },
  ],
};

////////////////////////////A travailler
const formersDetailsSearchDetails = {
  title: "Selection formateur",
  navItems: [
    {
      id: "uri-formers-details-search-detail",
      path: "/formers/:formersid/search/:searchid",
      label: "Selection formateur",
    },
  ],
};

const formersCreate = {
  title: "Nouveau Formateur",
  navItems: [
    {
      id: "uri-formers-create",
      path: "/formers/create",
      label: "Créer",
    },
  ],
};

const students = {
  title: "Etudiants",
  navItems: [{ id: "uri-students", path: "/students", label: "Liste" }],
};

const studentsDetails = {
  title: "Etudiants",
  navItems: [
    {
      id: "uri-students-details",
      path: "/students/:studentsid",
      label: "Details",
      exact: true,
    },
    {
      id: "uri-students-details-availability",
      path: "/students/:studentsid/availability",
      label: "Disponibilités",
    },
    {
      id: "uri-students-details-recherche",
      path: "/students/:studentsid/search",
      label: "Formations",
    },
    {
      id: "uri-students-details-calendar",
      path: "/students/:studentsid/calendar",
      label: "Calendrier",
    },
    // {
    //   id: "uri-students-details-formers",
    //   path: "/students/:studentsid/formers",
    //   label: "Formateurs",
    // },
  ],
};

////////////////A travailler
const studentsDetailsFormersDetails = {
  title: "Detail",
  navItems: [
    {
      id: "uri-students-details-formers-detail",
      path: "/students/:studentsid/formers/:formersid",
      label: "Detail",
    },
  ],
};
const studentsDetailsSearchDetails = {
  title: "Detail",
  navItems: [
    {
      id: "uri-students-details-trainings-detail",
      path: "/students/:studentsid/search/:searchid",
      label: "Detail",
    },
  ],
};

const studentsDetailsSearchCreate = {
  title: "Etudiants",
  navItems: [
    {
      id: "uri-students-details-trainings-create",
      path: "/students/:studentsid/search/create",
      label: "Ajouter une formation",
    },
  ],
};

const studentsDetailsSearchCreateCalendar = {
  title: "Etudiants",
  navItems: [
    {
      id: "uri-students-details-trainings-create-calendar",
      path: "/students/:studentsid/search/create/:formationsid",
      label: "Selectionner un formateur",
    },
  ],
};

const studentsCreate = {
  title: "Etudiants",
  navItems: [
    {
      id: "uri-students-create",
      path: "/students/create",
      label: "Créer",
    },
  ],
};

const formationsListing = {
  title: "Formations",
  navItems: [{ id: "uri-formations", path: "/formations", label: "Liste" }],
};

const formationsDetails = {
  title: "Formations",
  navItems: [
    {
      id: "uri-formations-details",
      path: "/formations/:formationsid",
      label: "Details",
      exact: true,
    },
  ],
};

const formationsCreate = {
  title: "Formations",
  navItems: [
    {
      id: "uri-formations-create",
      path: "/formations/create",
      label: "Créer",
    },
  ],
};

const account = {
  title: "Account",
  navItems: [
    { id: "uri-account", path: "/account", label: "Detail" },
    { id: "uri-account-create", path: "/account/create", label: "Create" },
  ],
};

const accountCreate = {
  title: "Creation d'un utilisateur",
  navItems: [
    { id: "uri-account", path: "/account", label: "Detail" },
    { id: "uri-account-create", path: "/account/create", label: "Create" },
  ],
};

const settings = {
  title: "Settings",
  navItems: [
    { id: "uri-settings-users", path: "/settings/users", label: "Users" },
    {
      id: "uri-settings-trainings",
      path: "/settings/trainings",
      label: "Trainings",
    },
  ],
};

const settingsUsersDetails = {
  title: "Settings",
  navItems: [
    {
      id: "uri-settings-users-detail",
      path: "/settings/users/:usersid",
      label: "Users",
    },
  ],
};

const settingsTrainingsDetails = {
  title: "Settings",
  navItems: [
    {
      id: "uri-settings-trainings-detail",
      path: "/settings/trainings/:trainingsid",
      label: "Detail",
    },
  ],
};

const routing = {
  "/": dashboard,
  "/formers": formersListing,
  "/formers/:formersid": formersDetails,
  "/formers/:formersid/calendar": formersDetails,
  "/formers/:formersid/students": formersDetails,
  "/formers/create": formersCreate,
  "/formers/:formersid/students/:studentsid": formersDetailsStudentsDetails,
  "/formers/:formersid/search": formersDetails,
  "/formers/:formersid/search/create": formersDetailsSearchCreate,
  "/formers/:formersid/search/:searchid": formersDetailsSearchDetails,
  "/students": students,
  "/students/:studentsid": studentsDetails,
  "/students/:studentsid/calendar": studentsDetails,
  "/students/:studentsid/formers": studentsDetails,
  "/students/create": studentsCreate,
  "/students/:studentsid/formers/:formersid": studentsDetailsFormersDetails,
  "/students/:studentsid/search": studentsDetails,
  "/students/:studentsid/search/:searchid": studentsDetailsSearchDetails,
  "/students/:studentsid/search/create": studentsDetailsSearchCreate,
  "/students/:studentsid/search/create/:formationsid":
    studentsDetailsSearchCreateCalendar,
  "/students/:studentsid/availability": studentsDetails,
  "/formations": formationsListing,
  "/formations/:formationsid": formationsDetails,
  "/formations/create": formationsCreate,
  "/account": account,
  "/account/create": accountCreate,
  "/settings/users": settings,
  "/settings/users/:usersid": settingsUsersDetails,
  "/settings/trainings": settings,
  "/settings/trainings/:trainingsid": settingsTrainingsDetails,
};

export default routing;
