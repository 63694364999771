
import styled from "styled-components";
import DayInstructor from "../DayInstructor";

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
`;

function openDetail(day, setCurrentDate, setCurrentView, e) {
  e.preventDefault();
  e.stopPropagation();
  console.log(day.date);
  setCurrentView("day");
  setCurrentDate(day.date);
}

function WeekInstructor({
  date = new Date(),
  setCurrentDate,
  setCurrentView,
  events,
  selectId,
  popUpPosition,
}) {
  const currentIndex = date.getDay();
  const prevDays = [...new Array(currentIndex)]
    .map((_, i) => {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() - i);
      return { id: newDate.getTime(), date: newDate, day: newDate.getDay() };
    })
    .reverse();

  const nextDays = [...new Array(7 - currentIndex)].map((_, i) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + (i + 1));
    return { id: newDate.getTime(), date: newDate, day: newDate.getDay() };
  });
  const days = [...prevDays, ...nextDays];

  return (
    <Container>
      {console.log("week index : ", selectId)}
      {days.map((day, index) => (
        <Content
          key={day.id}
          onClick={(e) =>
            // (e) => console.log("day : ", day)
            openDetail(day, setCurrentDate, setCurrentView, e)
          }
        >
          {/* <DayNumber>{daysMap[index]["date"]}</DayNumber>
          <DayLabel className={day.id === currentIndex ? "active" : ""}>
            {labelDays[index + 1]}
          </DayLabel> */}
          {/* {console.log(day.date)} */}
          <DayInstructor
            currentDate={day.date}
            displayTime={index === 0}
            displayCurrentTime={index === 0}
            type="week"
            onDay={false}
            events={events}
            selectId={selectId}
            popUpPosition={popUpPosition}
          ></DayInstructor>
        </Content>
      ))}
    </Container>
  );
}

export default WeekInstructor;

// function Week() {
//   const daysMap = [...new Array(7)].map((_, index) => ({
//     id: index + 1,
//     label: `${index + 1}`,
//   }));
//   console.log(daysMap[0]);
//   console.log(daysMap, [...new Array(7)]);

//   return (
//     <Container>
//       {daysMap.map((day, index) => (
//         <Day displayTime={index === 0} />
//       ))}
//     </Container>
//   );
// }

// export default Week;

// function Day({ date = new Date() }) {
//   const currentDay = getCurrentDay(date);
//   console.log(currentDay, getCurrentLabel(date), Object.values(labelDays));
//   return (
//     <div>
//       {Object.values(labelDays).map((label) => (
//         <div>{label}</div>
//       ))}
//     </div>
//   );
// }
