import { useState } from "react";
import Page from "../../../../../Layout/Page";
import Form from "../../../../../Common/Form";
import SubTitleBar from "../../../../../Common/Form/SubTitleBar";
import Field from "../../../../../Common/Form/Field";
import Icon from "../../../../../Common/Icon";

function FormerStudentsDetails() {
  const [disabled, setDisabled] = useState(true);

  return (
    <>
      <Page>
        <Form>
          <div className="form-row space-between">
            <SubTitleBar>Students Informations</SubTitleBar>
            <Icon
              name={disabled ? "edit" : "cancel"}
              className="button-edit"
              onClick={() => setDisabled(!disabled)}
            />
          </div>
          <div className="form-row">
            <Field label="Nom :" disabled={disabled}></Field>
            <Field label="Prénom :" disabled={disabled}></Field>
          </div>
          <div className="form-row">
            <Field label="Email :" disabled={disabled}></Field>
            <Field label="Type de formation :" disabled={disabled}></Field>
          </div>
        </Form>
      </Page>
    </>
  );
}

export default FormerStudentsDetails;
