import CalendarSelector from "../../../../../../../Common/Calendar/CalendarSelector";
import Page from "../../../../../../../Layout/Page";

function SelectCalendar({ match }) {
  return (
    <>
      <Page>
        <CalendarSelector></CalendarSelector>
        {/* <CalendarSelector
          path={`/students/${match.params.studentsid}/search/`}
        ></CalendarSelector> */}
      </Page>
    </>
  );
}

export default SelectCalendar;
