import Calendar from "../../../../Common/Calendar";
import Page from "../../../../Layout/Page";
import { getStudentDetailsCalendar } from "../../../../store/actions/students";
import {
  getStudentDetailsProgram,
  getStudentDetailsEventsDisponibility,
} from "../../../../store/actions/students";
import { connect } from "../../../../store";
import { useEffect, useState } from "react";

function StudentsCalendar({
  match,
  events,
  dispo,
  data,
  getStudentDetailsCalendar,
  getStudentDetailsProgram,
  getStudentDetailsEventsDisponibility,
}) {
  const [disponibility, setDisponibility] = useState(dispo);

  useEffect(() => {
    getStudentDetailsCalendar(match.params.studentsid);
    getStudentDetailsProgram(match.params.studentsid);
    getStudentDetailsEventsDisponibility(match.params.studentsid);
    console.log("students/details/calendar");
  }, [
    getStudentDetailsCalendar,
    getStudentDetailsProgram,
    getStudentDetailsEventsDisponibility,
    match.params.studentsid,
  ]);

  useEffect(() => {
    setDisponibility(dispo);
    console.log("students/details/calendar");
  }, [dispo]);

  return (
    <>
      <Page>
        <Calendar
          events={events}
          weekDispo={dispo}
          dispo={disponibility}
          setDispo={setDisponibility}
          program={data}
          selectId={match}
          type="week"
        />
      </Page>
    </>
  );
}

export default connect(
  (state) => ({
    dispo: state.students.details.eventsdisponibility,
    events: state.students.details.calendar.data,
    loading: state.students.details.calendar.loading,
    error: state.students.details.calendar.error,
    data: state.students.details.program.listing.data,
  }),
  {
    getStudentDetailsCalendar,
    getStudentDetailsProgram,
    getStudentDetailsEventsDisponibility,
  }
)(StudentsCalendar);
