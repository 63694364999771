import styled from "styled-components";

const Container = styled.div`
  width: 30%;
  overflow: visible;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
  margin-right: 5px;
`;

const Input = styled.input`
  flex: 4;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-right: 5px;
`;

const Button = styled.button`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export default function InputTime({
  label,
  fieldLabel,
  onChange,
  value,
  onClick,
  disabled,
  type,
}) {
  return (
    <Container>
      <Label>{label}</Label>
      <Input
        placeholder="Nombre d'heures à la suite"
        onChange={onChange}
        value={value}
      />
      <Button onClick={onClick}>Valider</Button>
    </Container>
  );
}
