import axios from "axios";

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.withCredentials = false;
// const back_url = process.env.REACT_APP_BACK_URL;
// const back_url = "https://api.formationaccess.fr";
const back_url="https://sleepy-plains-30984.herokuapp.com"

const proxy = (url) => {
  switch (true) {
    case url.includes("/api"):
      return url.replace("/api", back_url);

    default:
      return url;
  }
};

const override = {
  post: (url, ...params) => {
    return axios.post(proxy(url), ...params);
  },
  get: (url, ...params) => {
    return axios.get(proxy(url), ...params);
  },
};

export default override;
